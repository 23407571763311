import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Card } from '../../components/Card/Card';
import { Markdown } from '../../components/Markdown';
import { RateLab } from './RateLab';

const Explanation = ({ assertionChecks }) => {
  if (assertionChecks === null) {
    return null;
  }

  return assertionChecks.map((assertion) => {
    return assertion.status === 'failed' ? (
      <ExplanationText key={assertion.assertionCheckUuid}>{assertion.failureFeedback}</ExplanationText>
    ) : null;
  });
};

const Objective = ({ isPassed, name, assertionChecks }) => {
  return (
    <SingleObjective>
      <ObjectiveText>
        {name} {isPassed === null ? <></> : <Chip passed={isPassed}>{isPassed ? 'Passed' : 'Failed'}</Chip>}
      </ObjectiveText>
      {!isPassed && <Explanation assertionChecks={assertionChecks} />}
    </SingleObjective>
  );
};

const LabResults = ({ description = '', objectives = [], labId, assessmentId, ...props }) => {
  return (
    <LabCard title="Lab" {...props}>
      <Markdown text={description} />
      <ObjectivesWrapper>
        {objectives.map((objective) => {
          return (
            <div key={objective.id}>
              <Objective
                key={objective.id}
                isPassed={objective.isPassed}
                name={objective.name}
                assertionChecks={objective.assertionChecks}
              />
              <HorizontalDivider />
            </div>
          );
        })}
      </ObjectivesWrapper>

      <Footer data-testid={'lab-0-rating'}>
        <RateLab labId={labId} assessmentId={assessmentId} />
      </Footer>
    </LabCard>
  );
};

const LabCard = styled(Card)`
  width: 65%;
`;

const ObjectivesWrapper = styled.div`
  margin-top: 24px;
`;

const SingleObjective = styled.div`
  padding: 8px 0;
`;

const Footer = styled.footer`
  margin-top: 28px;
`;

const Chip = styled.span`
  background: ${({ passed }) => (passed ? colours.green50 : colours.red50)};
  color: ${({ passed }) => (passed ? colours.green700 : colours.red700)};
  font-size: 12px;
  padding: 3px 6px;
  text-transform: uppercase;
  line-height: 18px;
`;

const ObjectiveText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${colours.darkGrey900};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
`;

const ExplanationText = styled.p`
  font-size: 14px;
  color: ${colours.darkGrey900};
  margin-bottom: 0;
`;

const HorizontalDivider = styled.hr`
  margin: 0;
  height: 1px;
  border: none;
  background: ${colours.lightGrey500};
`;

export { LabResults };
