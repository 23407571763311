import React from 'react';
import styled from 'styled-components';
import { colours, Button } from '@a-cloud-guru/rainbow-ui';
import sadCloud from './images/sadcloud.png';
import { getConfig } from '../../config/get-config';

const { LEARN_URL } = getConfig();

const NotFound = () => {
  return (
    <Layout showLogo={true}>
      <Container>
        <SadCloud src={sadCloud}></SadCloud>

        <Title>Oh no!</Title>
        <Blurb>The page you’re looking for doesn't exist.</Blurb>

        <Button type="primary">
          <a href={`${LEARN_URL}/dashboard`}>Return to your Dashboard</a>
        </Button>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(${colours.indigo700}, ${colours.navy700} 40%);
`;

const Container = styled.div`
  padding-top: 10%;
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
`;

const SadCloud = styled.img`
  width: 300px;
  height: 200px;
`;

const Title = styled.div`
  margin-top: 71px;
  color: ${colours.lightGrey200};
  font-size: 2.5em;
  font-weight: 700;
`;

const Blurb = styled.div`
  color: ${colours.indigo200};
  font-size: 1.1em;
  margin-bottom: 40px;
  margin-top: 8px;
`;

export { NotFound };
