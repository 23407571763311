import React from 'react';
import { Rating } from '../../components/Rating/Rating';

const RateLab = ({ labId, assessmentId }) => {
  return (
    <Rating
      type="compact"
      category="lab"
      entityId={`exam::${assessmentId}::lab::${labId}`}
      defaultText="Rate this lab"
      upvotedText="You liked this lab"
      downvotedText="You disliked this lab"
    />
  );
};

export { RateLab };
