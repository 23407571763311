import React from 'react';
import { ReactComponent as CopyIcon } from './copy-icon.svg';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { copyText } from '../../lib/copyText';
import { successMessage } from '../../components/Message/Message';

const CopyButton = ({ value, onClick = () => {} }) => {
  return (
    <UnstyledButton
      type="button"
      onClick={() => {
        onClick();
        copyText(value);
        successMessage('Copied to clipboard');
      }}
    >
      <CopyIcon />
    </UnstyledButton>
  );
};

const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  height: 36px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  &:hover {
    svg {
      path {
        fill: ${colours.darkGrey600}} ;
      }
    }
  }
`;

export { CopyButton };
