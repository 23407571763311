import React from 'react';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '../images/arrow.svg';
import { StyledLink } from './StyledLink';

const FailedLink = ({ url, score, disabled, ...props }) => {
  if (disabled) {
    return <DisabledSpan {...props}>Failed ({score}%)</DisabledSpan>;
  }

  return (
    <StyledLink href={url} color={colours.red700} hoverColor={colours.red500} {...props}>
      Failed ({score}%) <ArrowIcon />
    </StyledLink>
  );
};

const ArrowIcon = styled(Arrow)`
  margin-left: 4px;
`;

const DisabledSpan = styled.span`
  font-weight: 600;
  color: ${colours.darkGrey50};
`;

export { FailedLink };
