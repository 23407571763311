import React from 'react';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';

const IncompleteLabel = (props) => <StyledSpan {...props}>Incomplete</StyledSpan>;

const StyledSpan = styled.span`
  font-weight: 600;
  color: ${colours.indigo700};

  svg {
    path {
      fill: ${colours.indigo700};
    }
  }
`;

export { IncompleteLabel };
