import React from 'react';
import { useParams } from 'react-router-dom';
import { Rating } from '../../components/Rating/Rating';

const RateExam = () => {
  const { assessmentId } = useParams();

  return (
    <Rating
      type="compact"
      category="exam"
      entityId={`exam::${assessmentId}`}
      defaultText="Rate this practice exam"
      upvotedText="You liked this practice exam"
      downvotedText="You disliked this practice exam"
    />
  );
};

export { RateExam };
