import React from 'react';
import styled from 'styled-components';
import { Button, colours } from '@a-cloud-guru/rainbow-ui';

const StartExamButton = ({ hasBeenAttempted, ...props }) => (
  <StyledButton type="primary" {...props}>
    {hasBeenAttempted ? 'Start another exam' : 'Start exam'}
  </StyledButton>
);

const StyledButton = styled(Button)`
  &:focus {
    border-radius: 4px;
    box-shadow: ${colours.blue500} 0px 0px 4px 2px;
  }
`;

export { StartExamButton };
