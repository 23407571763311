import { colours } from '@a-cloud-guru/rainbow-ui/lib/tokens';
import { Card } from 'antd';
import styled from 'styled-components';

const UserBanned = () => {
  return (
    <Container>
      <UserBannedCard style={{ backgroundColor: 'white', borderLeft: `2px solid ${colours.red700}` }}>
        <h1>
          You have been temporarily blocked from using Hands-On Labs &amp; you will not be able to complete this
          assessment
        </h1>
        <Text>
          <strong>Your Hands-On Lab or Cloud Playground was shut down because of detected misuse.</strong> As a
          reminder, we place some restrictions on the use of Hands-On Labs and Cloud Playground. Examples of the
          restrictions include, but are not limited to:
        </Text>
        <UnorderedList>
          <li>Ten or more instances created at a time</li>
          <li>Attempting to use resources for Bitcoin mining</li>
          <li>Excessive network traffic</li>
          <li>DDoS or port scanning external hosts</li>
          <li>Google Cloud: using an Accelerator</li>
          <li>Incorrect instance type</li>
          <li>Ten or or more vCPU across all instances</li>
        </UnorderedList>
        <Text>
          Hands-On Labs and Cloud Playground are provided for educational purposes only. You can review the Terms of
          Service{' '}
          <a href="https://acloudguru.com/policies/terms-of-use" isExternal={true}>
            here
          </a>
          .
        </Text>
        <Text>
          Visit this link to learn more about what activities are permitted: &nbsp;
          <a href="https://help.pluralsight.com/help/cloud-playground" isExternal={true}>
            Cloud Playground – A Cloud Guru.
          </a>
        </Text>
        <Text>You will be able to resume practice in 72 hours.</Text>
      </UserBannedCard>
    </Container>
  );
};

const UserBannedCard = styled(Card)`
  background-color: white;
  width: 1123px;
  margin: 24px 0;
`;

const Container = styled.div`
  display: flex;
`;

const Text = styled.p`
  margin-bottom: 5px;
`;

const UnorderedList = styled.ul`
  padding-left: 15px;
`;

export { UserBanned };
