import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colours, Button } from '@a-cloud-guru/rainbow-ui';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitContext } from '../../contexts/submit/SubmitContext';
import { errorMessage } from '../../components/Message/Message';
import { Modal } from '../../components/Modal/Modal';
import { CURRENT_ATTEMPT } from '../../lib/sharedGraphql';
import { START_LAB } from '../../lib/graphql/startLab';

const showErrorMessage = (message) =>
  errorMessage(message || 'Oops, something went wrong! Please try again.', 'exam-page');

const SubmitExam = ({ questions, hasLabs }) => {
  const [visible, setVisible] = useState(false);
  const { submitExam, loading: submitExamLoading } = useContext(SubmitContext);
  const { assessmentId, attemptId } = useParams();
  const history = useHistory();

  const numberOfUnanswered = questions.filter((question) => !question.submitted).length;
  const numberOfFlagged = questions.filter((question) => question.flagged).length;

  const [startLab, { loading: startLabLoading }] = useMutation(START_LAB, {
    variables: {
      input: {
        attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    refetchQueries: [
      {
        query: CURRENT_ATTEMPT.QUERY,
        variables: {
          input: {
            id: attemptId,
            assessmentId,
          },
          contentType: 'PRACTICE_EXAM',
        },
      },
    ],
    awaitRefetchQueries: true,
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      const responseType = data?.Assessments_v2_startLab?.__typename;

      switch (responseType) {
        case 'Assessments_AttemptAlreadyGraded':
          showErrorMessage('This exam has been completed');
          history.push(`/${assessmentId}/result/${attemptId}`);
          break;
        case 'Assessments_StartLabError':
          showErrorMessage();
          break;
        default:
          history.push(`/${assessmentId}/lab/${attemptId}`);
      }
    },
  });

  const handleClick = () => {
    setVisible(true);
  };

  const handleSubmit = () => {
    if (hasLabs) {
      return startLab();
    }

    submitExam();
    setVisible(false);
  };

  const handleContinue = () => {
    setVisible(false);
  };

  return (
    <>
      <SubmitQuestionsButton type="primary" data-testid="exam-page-submit-button" onClick={handleClick} tabIndex="0">
        {hasLabs ? 'Submit Questions' : 'Submit Exam'}
      </SubmitQuestionsButton>
      <Modal
        wrapClassName="exam-page-submit-modal"
        title={hasLabs ? 'Ready to submit questions?' : 'Ready to submit exam?'}
        visible={visible}
        onCancel={handleContinue}
        footer={[
          <FocusableButton
            key="continue"
            type="outline"
            onClick={handleContinue}
            data-testid="exam-page-continue-button"
            tabIndex="0"
          >
            {hasLabs ? 'Continue questions' : 'Continue exam'}
          </FocusableButton>,
          <FocusableButton
            key="submit"
            type="primary"
            data-testid="exam-page-complete-button"
            disabled={submitExamLoading || startLabLoading}
            loading={submitExamLoading || startLabLoading}
            onClick={handleSubmit}
            tabIndex="0"
          >
            {hasLabs ? 'Move on to labs' : 'Submit exam'}
          </FocusableButton>,
        ]}
      >
        {hasLabs ? (
          <>
            <p>
              Are you ready to submit your questions and move on to labs? This will stop any active labs you have in
              progress.
            </p>
            <p>
              You will <strong>not be able to return</strong> and change answers.
            </p>
          </>
        ) : (
          <p>Are you ready to submit your exam?</p>
        )}
        <p data-testid="exam-page-submit-flagged">
          You have <strong>{numberOfFlagged}</strong> flagged question{numberOfFlagged !== 1 ? 's' : ''}.
        </p>
        <p data-testid="exam-page-submit-unanswered">
          You have <strong>{numberOfUnanswered}</strong> unanswered question{numberOfUnanswered !== 1 ? 's' : ''}.
        </p>
      </Modal>
    </>
  );
};

const FocusableButton = styled(Button)`
  :focus {
    border-radius: 4px;
    box-shadow: ${rgba(colours.blue500, 1)} 0px 0px 4px 2px;
  }
`;

const SubmitQuestionsButton = styled(Button)`
  width: 100%;
  margin-bottom: 8px;
  &.ant-btn {
    padding-top: 6px !important; // Annoying antD override
  }
  :focus {
    border-radius: 4px;
    box-shadow: ${rgba(colours.blue500, 1)} 0px 0px 4px 2px;
  }
  span {
    margin-left: 8px;
  }
`;

export { SubmitExam };
