import React from 'react';
import { SingleSelect } from './SingleSelect';
import { MultiSelect } from './MultiSelect';

const Choices = ({ questionIndex, submitted, question }) => {
  const { choices = [], type, possibleCorrect } = question;

  switch (type) {
    case 'multi-answer':
      return (
        <MultiSelect
          choices={choices}
          submitted={submitted}
          possibleCorrect={possibleCorrect}
          selectedChoiceIds={question.selectedChoiceIds}
          questionIndex={questionIndex}
        />
      );

    case 'single-answer':
    default:
      return <SingleSelect choices={choices} submitted={submitted} selectedChoiceIds={question.selectedChoiceIds} />;
  }
};

export { Choices };
