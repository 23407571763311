import React from 'react';
import get from 'lodash/get';
import { Ratings } from '@a-cloud-guru/rainbow-ui';
import { useRating } from '../../hooks/useRating';
import { FullLoading } from './FullLoading';
import { CompactLoading } from './CompactLoading';

const Rating = ({ type = 'full', category, entityId, ...props }) => {
  const { data, loading, upvote, downvote } = useRating({
    category,
    entityId,
  });

  return (
    <Ratings
      type={type}
      value={get(data, 'vote') || ''}
      loading={loading}
      upvote={upvote}
      downvote={downvote}
      renderLoading={() => (type === 'full' ? <FullLoading /> : <CompactLoading />)}
      {...props}
    />
  );
};

export { Rating };
