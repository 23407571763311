import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import { colours, breakpoints } from '@a-cloud-guru/rainbow-ui';
import { SectionHeading } from '../../components/SectionHeading';
import { SubmitExam } from './SubmitExam';
import { NavigationItem } from '../../components/NavigationItem/NavigationItem';
import { UPDATE_QUESTION_INDEX } from './../../lib/sharedGraphql';
import { PrevNextNavigation } from './PrevNextNavigation';
import { errorMessage } from '../../components/Message/Message';

const NavigationButtons = ({ attempt, questionIndex, assessmentId }) => {
  const { questions = [] } = attempt;
  const showErrorMessage = (message) =>
    errorMessage(message || 'Oops, something went wrong! Please try again.', 'exam-page');

  const [updateIndex] = useMutation(UPDATE_QUESTION_INDEX.MUTATION, {
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      if (!get(data, 'Assessments_v2_updateQuestionIndex')) {
        showErrorMessage(
          'You do not have permission to perform this action. Check your subscription and try again later.'
        );
        return;
      }

      const message = get(data, 'Assessments_v2_updateQuestionIndex.message');
      if (message) {
        showErrorMessage('This exam has been completed');
      }
    },
  });

  const selectQuestion = (questionNumber) => {
    updateIndex(UPDATE_QUESTION_INDEX.options({ questionNumber, attempt, assessmentId }));
  };

  return questions?.map((question, index) => {
    return (
      <NavigationItem
        key={question.id}
        isSubmitted={question.submitted || null}
        isActive={questionIndex === index}
        handleClick={() => selectQuestion(question.order)}
        isFlagged={question.flagged}
      >
        {question.order + 1}
      </NavigationItem>
    );
  });
};

const QuestionProgress = ({ attempt }) => {
  const submittedAnswers = attempt.questions.filter((question) => question.submitted);

  return (
    <ProgressContainer data-testid="exam-page-question-progress">
      You’ve answered {submittedAnswers.length} of {attempt.numberOfQuestions} questions.
    </ProgressContainer>
  );
};

const Navigation = ({ attempt, questionIndex, assessmentId }) => {
  const { questions, numberOfLabs } = attempt;
  const hasLabs = numberOfLabs > 0;

  return (
    <NavigationContainer>
      <NavigationCard data-testid="exam-page-navigation-container">
        <SectionHeading>Navigate</SectionHeading>
        <NavigationButtonWrapper>
          <NavigationButtons attempt={attempt} questionIndex={questionIndex} assessmentId={assessmentId} />
        </NavigationButtonWrapper>
        <SubmitExam questions={questions} hasLabs={hasLabs} />
        <QuestionProgress attempt={attempt} />
      </NavigationCard>
      <PrevNextNavigation attempt={attempt} assessmentId={assessmentId} />
    </NavigationContainer>
  );
};

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavigationCard = styled.nav`
  box-shadow: 0px 2px 14px rgba(173, 186, 208, 0.303431);
  border-radius: 5px;
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  width: 728px;
  overflow: auto;
  height: auto;
  min-height: 215px;
  background: ${colours.white};
  padding: 24px 16px 24px 24px;
  @media (min-width: ${breakpoints.xl}) {
    align-self: flex-start;
    width: 346px;
    margin-top: 0;
  }
`;

const NavigationButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0px;
  justify-content: flex-start;
`;

const ProgressContainer = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-top: 8px;
  color: ${colours.darkGrey200};
`;

export { Navigation };
