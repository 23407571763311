import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Markdown } from '../../components/Markdown';
import { ReactComponent as GreenCheck } from './images/greenCheck.svg';
import { ReactComponent as GrayX } from './images/grayX.svg';
import { ReactComponent as RedX } from './images/redX.svg';
import { ReactComponent as GrayCircle } from './images/grayCircle.svg';

const getIcon = ({ choice, selectedChoiceIds, revealAnswer, domainIndex }) => {
  if (selectedChoiceIds.length === 0 && !revealAnswer) {
    return <GrayCircleIcon />;
  }

  if (choice.correctAnswer) {
    return <GreenCheck data-testid={`domain-${domainIndex}-question-choice-result-green-check-icon`} />;
  }

  if (!choice.correctAnswer && selectedChoiceIds.includes(choice.id)) {
    return <RedX />;
  }

  return <GrayX />;
};

const getSelectedTag = ({ choice, selectedChoiceIds }) => {
  if (choice.correctAnswer && selectedChoiceIds.includes(choice.id)) {
    return <SelectedTag>Selected</SelectedTag>;
  }

  if (!choice.correctAnswer && selectedChoiceIds.includes(choice.id)) {
    return <SelectedIncorrectTag>Selected</SelectedIncorrectTag>;
  }

  return '';
};

const shouldShowExplanation = ({ choice, selectedChoiceIds, revealAnswer }) => {
  const explanation = choice?.explanation;
  if (!revealAnswer || !explanation) {
    return false;
  }

  if (choice.correctAnswer) {
    return true;
  }

  if (!choice.correctAnswer && selectedChoiceIds.includes(choice.id)) {
    return true;
  }

  return false;
};

const QuestionChoiceResult = ({ choice, selectedChoiceIds, domainIndex, revealAnswer, choiceIndex }) => {
  const { explanation } = choice;
  const selectedTag = getSelectedTag({ choice, selectedChoiceIds });
  const paragraphColor =
    selectedChoiceIds.includes(choice.id) || choice.correctAnswer || !revealAnswer
      ? colours.darkGrey900
      : colours.darkGrey50;
  const hasAnswerSelected = selectedChoiceIds.length > 0;

  return (
    <ChoiceWrapper data-testid={`domain-${domainIndex}-question-choice-${choiceIndex}-result-wrapper`}>
      {getIcon({ choice, selectedChoiceIds, domainIndex, revealAnswer })}
      <ChoiceExplanationWrapper revealAnswer={revealAnswer} className={hasAnswerSelected ? 'has-answer-selected' : ''}>
        <ExplanationMarkdown
          hasAnswerSelected={hasAnswerSelected}
          paragraphColor={paragraphColor}
          data-testid={`domain-${domainIndex}-question-choice-${choiceIndex}-result-text`}
          text={choice.text}
        />

        {shouldShowExplanation({ choice, selectedChoiceIds, revealAnswer }) && (
          <Explanation
            data-testid={`explanation-${domainIndex}-${choiceIndex}`}
            correct={choice.correctAnswer}
            hasAnswerSelected={hasAnswerSelected}
          >
            <ExplanationMarkdown
              paragraphColor={colours.darkGrey700}
              data-testid={`domain-${domainIndex}-question-choice-${choiceIndex}-result-text`}
              text={explanation}
            />
          </Explanation>
        )}
      </ChoiceExplanationWrapper>
      {selectedTag}
    </ChoiceWrapper>
  );
};

const ExplanationMarkdown = styled(Markdown)`
  pre {
    max-width: 100%;
  }

  .has-answer-selected & {
    pre {
      max-width: 572px;
    }
  }
`;

const GrayCircleIcon = styled(GrayCircle)`
  margin-top: 9px !important;
`;

const SelectedTag = styled.div`
  align-self: flex-start;
  text-align: center;
  color: ${colours.green700};
  background-color: ${colours.green50};
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 6px;
  margin-left: 14px;
  border-radius: 3px;
  width: 65px;
`;

const SelectedIncorrectTag = styled(SelectedTag)`
  color: ${colours.red700};
  background-color: ${colours.red50};
`;

const ChoiceWrapper = styled.div`
  display: flex;

  svg {
    min-width: 20px;
    margin-top: 5px;
    margin-right: 11px;
    circle {
      fill: ${colours.darkGrey900};
    }
  }
`;

const ChoiceExplanationWrapper = styled.div`
  margin-bottom: 16px;
  max-width: 649px;

  &.has-answer-selected {
    max-width: none;
    flex: 1;
  }
`;

const Explanation = styled.div`
  color: ${colours.darkGrey700};
  border-left: 2px solid ${({ correct }) => (correct ? colours.green200 : colours.red50)};
  margin: 8px 0 0;
  padding-left: 20px;

  .has-answer-selected & {
    max-width: 572px;
  }
`;

export { QuestionChoiceResult, getSelectedTag, getIcon };
