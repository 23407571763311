import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { differenceInMilliseconds } from 'date-fns';
import { formatTime } from './../../lib/util';

const Timer = ({ endTime, onExpire }) => {
  let timerColor = colours.darkGrey200;

  const calculateRemainingTime = () => {
    const milliseconds = differenceInMilliseconds(new Date(endTime), new Date());
    let timeLeft = '00:00:00';

    if (milliseconds > 0) {
      timeLeft = formatTime(milliseconds);
      if (milliseconds < 600000) {
        timerColor = colours.darkGrey500;
      }
    }

    return timeLeft;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateRemainingTime());
  const [called, setCalled] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = calculateRemainingTime();
      setTimeRemaining(timeLeft);
      if (timeLeft === '00:00:00' && !called) {
        setCalled(true);
        clearInterval(interval);
        onExpire();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <TimerContainer timerColor={timerColor}>{timeRemaining}</TimerContainer>;
};

const TimerContainer = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => props.timerColor};
`;

export { Timer };
