import { gql } from '@apollo/client';

const GET_USER_BANNED = gql`
  query LabKeep_getAllPlaygroundLabs {
    LabKeep_getAllPlaygroundLabs {
      userBanned
    }
  }
`;

export { GET_USER_BANNED };
