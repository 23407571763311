import { gql } from '@apollo/client';

const START_LAB = gql`
  mutation Assessments_startLab(
    $input: Assessments_StartLabInput!
    $contentType: Assessments_AccessPermissions_ContentType!
  ) {
    Assessments_v2_startLab(input: $input, contentType: $contentType) {
      __typename
      ... on Assessments_Attempt {
        id
        title
        status
        description
        numberOfQuestions
        numberOfLabs
        assessmentEndDate
        currentQuestionIndex
        currentLabIndex
        coverImageUrl
        labs {
          id
          description
          instructions
          diagramUrl
          tasks {
            id
            name
            explanation
          }
        }
      }
      ... on Assessments_AttemptAlreadyGraded {
        message
      }
    }
  }
`;

export { START_LAB };
