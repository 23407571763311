import React from 'react';
import styled from 'styled-components';
import { colours, Skeleton } from '@a-cloud-guru/rainbow-ui';

const Header = ({ loading, timeLimit, numberOfQuestions, numberOfLabs, title, coverImageUrl }) => {
  return (
    <HeaderContainer>
      <HeaderImage coverImageUrl={coverImageUrl || '/images/defaultImage.png'}></HeaderImage>
      <TitleContainer>
        <Heading>Practice Exam</Heading>
        <Skeleton loading={loading} paragraph={false}>
          <ExamTitle data-testid="start-page-exam-title">{title}</ExamTitle>
        </Skeleton>
      </TitleContainer>
      <HeaderDetailsContainer>
        <Skeleton loading={loading} paragraph={false}>
          <HeaderDetails data-testid="start-page-exam-time-limit">{timeLimit}</HeaderDetails>
        </Skeleton>
        <Skeleton loading={loading} paragraph={false}>
          <HeaderDetails data-testid="start-page-exam-question-count">{numberOfQuestions} questions</HeaderDetails>
        </Skeleton>
        {numberOfLabs > 0 && (
          <Skeleton loading={loading} paragraph={false}>
            <HeaderDetails data-testid="start-page-exam-lab-count">
              {numberOfLabs} {numberOfLabs > 1 ? 'labs' : 'lab'}{' '}
            </HeaderDetails>
          </Skeleton>
        )}
      </HeaderDetailsContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
`;

const Heading = styled.h1`
  color: ${colours.darkGrey900};
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  margin: 0;
`;

const ExamInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 0 0 24px;
`;

const TitleContainer = styled(ExamInfo)`
  flex: 1;
`;

const ExamTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;

const HeaderImage = styled.div`
  width: 78px;
  min-width: 78px;
  height: 78px;
  background: ${colours.blue};
  border-radius: 3px;
  margin-top: 16px;
  margin-left: -62px;
  background-image: url('${(props) => props.coverImageUrl}');
  background-size: cover;
  background-position: center center;
`;

const HeaderDetailsContainer = styled(ExamInfo)`
  width: 20%;
`;

const HeaderDetails = styled.p`
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: ${colours.darkGrey600};
  margin: 0;
`;

export { Header };
