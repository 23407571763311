import React from 'react';
import styled from 'styled-components';
import { colours, breakpoints } from '@a-cloud-guru/rainbow-ui';

const Header = ({ examName, coverImageUrl, children }) => {
  const handleBrokenImage = (ev) => {
    ev.target.src = '/images/defaultImage.png';
  };

  return (
    <Container data-testid="header">
      <HeaderDetails>
        <CoverImage
          onError={handleBrokenImage}
          src={coverImageUrl || '/images/defaultImage.png'}
          alt="exam cover"
          data-testid="header-cover-image"
        />
        <HeaderDetailsSub>
          <ExamName>{examName || 'Exam'}</ExamName>
          <ExamSubText>Practice Exam</ExamSubText>
        </HeaderDetailsSub>
      </HeaderDetails>
      <HeaderSecondary>{children}</HeaderSecondary>
    </Container>
  );
};

const Container = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 72px;
  background: ${colours.white};
  padding: 0 2rem;
  @media (min-width: ${breakpoints.xl}) {
    padding: 0;
  }
`;

const HeaderDetails = styled.div`
  width: 728px;
  display: flex;
  justify-content: flex-start;
  margin-right: 51px;
  padding: 16px 0px 14px 0px;
`;

const HeaderDetailsSub = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderSecondary = styled.div`
  width: 346px;
  display: flex;
  justify-content: flex-end;
  padding: 22px 0px 20px 0px;
  align-items: center;
`;

const CoverImage = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 9px;
`;

const ExamName = styled.p`
  color: ${colours.darkGrey900};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-block-end: 0; // Override browser default
  margin-bottom: 0;
`;

const ExamSubText = styled(ExamName)`
  font-weight: 300;
`;

export { Header };
