import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Spinner } from '../Spinner';

const Overlay = ({ show = false, children }) => {
  return (
    <>
      {show && (
        <SpinnerLayer>
          <SpinnerContainer>
            <Spinner size="48px" data-testid="spinner" />
            <Message>Grading exam...</Message>
          </SpinnerContainer>
        </SpinnerLayer>
      )}
      <ChildrenLayer showOverlay={show}>{children}</ChildrenLayer>
    </>
  );
};

const SpinnerLayer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.p`
  text-shadow: 0px 0px 5px ${colours.white};
  margin-top: 16px;
  font-weight: 600;
`;

const ChildrenLayer = styled.div`
  opacity: ${(props) => (props.showOverlay ? 0.5 : 1)};
`;

export { Overlay };
