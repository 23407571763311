import { compose, getOr, isEqual, every, some } from 'lodash/fp';

const valueForKey = key =>
  compose(
    getOr({}, key),
    JSON.parse,
    getOr('{}', 'features')
  );

const hasAccessLevel = accessLevel => compose(isEqual(accessLevel), getOr('false', 'accessLevel'));

const featureByKey = accessLevel => key => compose(hasAccessLevel(accessLevel), valueForKey(key));

const makeFeatureHasAccessLevel = data => key => accessLevel => {
  try {
    return featureByKey(accessLevel)(key)(data);
  } catch (error) {
    console.error('Failed to parse feature access JSON', error);
  }
  return false;
};

const makeAllFeaturesHaveSameAccessLevel = data => (keys = []) => accessLevel => {
  try {
    return every((key) => featureByKey(accessLevel)(key)(data))(keys);
  } catch (error) {
    console.error('Failed to parse feature access JSON', error);
  }

  return false;
};

const makeAnyFeaturesHaveAccessLevel = data => (keys = []) => accessLevel => {
  try {
    return some((key) => featureByKey(accessLevel)(key)(data))(keys);
  } catch (error) {
    console.error('Failed to parse feature access JSON', error);
  }

  return false;
};

export { makeFeatureHasAccessLevel, makeAllFeaturesHaveSameAccessLevel, makeAnyFeaturesHaveAccessLevel };
