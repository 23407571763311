import React from 'react';
import { Spinner } from './Spinner';
import styled from 'styled-components';

const LoadingScreen = () => {
  return (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
      <LoadingSpinner size={'48px'} />
    </div>
  );
};

const LoadingSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export { LoadingScreen };
