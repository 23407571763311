import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { ReactComponent as ArrowIcon } from '../pages/Result/images/arrow.svg';

const BackLink = ({ url, linkText, ...props }) => (
  <StyledLink data-testid="logo-header-dashboard-button" href={url} {...props}>
    {linkText}
    <IconContainer>
      <ArrowIcon />
    </IconContainer>
  </StyledLink>
);

const StyledLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 6px 18px;
  border: 1px solid transparent;
  transition: all 3;

  &:hover {
    border-color: ${colours.lightGrey500};
    border-radius: 3px;
  }

  &:focus {
    border-radius: 4px;
    box-shadow: ${colours.blue500} 0px 0px 4px 2px;
  }
`;

const IconContainer = styled.span`
  display: flex !important;
  align-items: center;
  margin-top: 1px;
  margin-left: 7.5px;
  width: 16px;
  height: 18px;
`;

export { BackLink };
