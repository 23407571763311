import React from 'react';
import styled from 'styled-components';
import { colours, breakpoints } from '@a-cloud-guru/rainbow-ui';
import get from 'lodash/get';
import { SectionHeading } from '../../components/SectionHeading';
import { NavigationItem } from '../../components/NavigationItem/NavigationItem';

const DomainQuestionNavigation = ({ questions, setQuestionNumber, selectedQuestion, testIndex }) => {
  const domainQuestions = questions.map((question, index) => {
    return (
      <NavigationItem
        key={index}
        isCorrect={get(question, 'grade.isCorrect')}
        isSubmitted={question.submitted}
        isActive={question.order === selectedQuestion}
        handleClick={() => setQuestionNumber(question.order)}
      >
        {question.order + 1}
      </NavigationItem>
    );
  });

  return (
    <div data-testid={`domain-${testIndex}-question-navigation`}>
      <QuestionHeading>
        <SectionHeading>Questions</SectionHeading>
        <QuestionDivider />
      </QuestionHeading>
      <NavigationButtonWrapper data-testid={`domain-${testIndex}-question-navigation-buttons`}>
        {domainQuestions}
      </NavigationButtonWrapper>
    </div>
  );
};

const QuestionHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const QuestionDivider = styled.div`
  width: 207px;
  height: 1px;
  background-color: ${colours.lightGrey500};
  margin-left: 15px;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const NavigationButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export { DomainQuestionNavigation };
