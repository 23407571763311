import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import styled from 'styled-components';
import { breakpoints, Collapse, colours } from '@a-cloud-guru/rainbow-ui';
import { useQuery, useMutation, useLazyQuery, gql } from '@apollo/client';
import { SubmitContext } from '../../contexts/submit/SubmitContext';
import { CURRENT_ATTEMPT } from '../../lib/sharedGraphql';
import { SUBMIT_LAB_MUTATION } from './graphql/submitLab';
import { STORE_LAB_GRADE } from './graphql/storeLabGrade';
import { CHECK_OBJECTIVES } from './graphql/checkObjectives';
import { START_LAB } from '../../lib/graphql/startLab';
import { Header } from '../../components/Header/Header';
import { Markdown } from '../../components/Markdown';
import { Card } from '../../components/Card/Card';
import { Container, Background } from '../../components/Layout';
import { Timer } from '../../components/Header/Timer';
import { errorMessage } from '../../components/Message/Message';
import { Overlay } from '../../components/Overlay/Overlay';
import { Tools } from './Tools';
import { Credentials } from './Credentials';
import { SubmitLab } from './SubmitLab';
import { track } from '../../lib/segment';

const { Panel } = Collapse;

const LAB_STATUS = {
  STARTING: 'STARTING',
  READY: 'READY',
  ERROR: 'ERROR',
};

const MY_LAB_QUERY = gql`
  query getLabInstance {
    LabKeep_myLab {
      instance {
        id
        status
        uuid
        remoteDetails {
          credentials {
            credentials {
              url
              username
              password
            }
          }
        }
      }
    }
  }
`;

const Lab = () => {
  const { attemptId, assessmentId } = useParams();
  const history = useHistory();
  const [grading, setGrading] = useState(false);
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [isLabReady, setIsLabReady] = useState(false);
  const [hasTimerExpired, setHasTimerExpired] = useState(false);
  const { submitExam } = useContext(SubmitContext);

  const showErrorMessage = (message) =>
    errorMessage(message || 'Oops, something went wrong! Please try again.', 'lab-page');

  const {
    loading: loadingAttempt,
    data: attempt,
    refetch,
  } = useQuery(CURRENT_ATTEMPT.QUERY, {
    variables: {
      input: {
        id: attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (!get(data, 'Assessments_v2_attempt')) {
        showErrorMessage(
          'You do not have permission to perform this action. Check your subscription and try again later.'
        );
        return;
      }

      const assessmentAttempt = get(data, 'Assessments_v2_attempt', {});
      if (assessmentAttempt.status === 'incomplete') {
        return history.push(`/${assessmentId}/exam/${attemptId}`);
      }

      if (assessmentAttempt.status === 'passed' || assessmentAttempt.status === 'failed') {
        showErrorMessage('This exam has been completed');
        return history.push(`/${assessmentId}/result/${attemptId}`);
      }

      fetchCredentials();
    },
  });

  const [submitLab] = useMutation(SUBMIT_LAB_MUTATION, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    onCompleted(data) {
      const responseType = data?.Assessments_v2_submitLab?.__typename;

      switch (responseType) {
        case 'Assessments_NoActiveLabInstance':
          showErrorMessage('Attempt has no labs');
          break;
        case 'Assessments_AttemptAlreadyGraded':
          showErrorMessage('This exam has been completed');
          history.push(`/${assessmentId}/result/${attemptId}`);
          break;
        // Note: 'Assessments_SubmitLabError' error is not handled intentionally.
        // Based on observation, this error mostly occured because of double submission.
        // Instead of blocking student, it is better to let them continue.
        default:
          storeLabGrade();
      }
    },
    onError() {
      errorMessage('Oops, something went wrong! Please try again', 'lab-page');
    },
  });

  const [storeLabGrade] = useMutation(STORE_LAB_GRADE, {
    variables: {
      input: {
        attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    onCompleted(data) {
      const responseType = data?.Assessments_v2_storeLabGrade?.__typename;

      switch (responseType) {
        case 'Assessments_AttemptNotFound':
        case 'Assessments_NoActiveLabInstance':
        case 'Assessments_GradingSessionNotFound':
        case 'Assessments_StoreLabGradeError':
          showErrorMessage();
          setIsSubmitPending(false);
          setGrading(false);
          break;
        case 'Assessments_AttemptAlreadyCompleted':
          showErrorMessage('This exam has been completed');
          history.push(`/${assessmentId}/result/${attemptId}`);
          break;
        default:
          const isGraded = data?.Assessments_v2_storeLabGrade?.isGraded;

          if (isGraded) {
            return checkObjectives();
          }

          setTimeout(storeLabGrade, 8000);
      }
    },
  });

  const [checkObjectives] = useLazyQuery(CHECK_OBJECTIVES, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    onCompleted(data) {
      const currentLabIndex = data?.Assessments_v2_attempt?.currentLabIndex;
      const objectives = data?.Assessments_v2_attempt?.labs?.[currentLabIndex]?.tasks;

      const isAllObjectivesGraded = objectives.every((objective) => isBoolean(objective.isPassed));
      if (!isAllObjectivesGraded) {
        return setTimeout(checkObjectives, 8000);
      }

      setIsSubmitPending(false);

      const hasNextLab = currentLabIndex < data?.Assessments_v2_attempt?.numberOfLabs - 1;
      if (hasNextLab && !hasTimerExpired) {
        startLab();
      } else {
        submitExam();
      }
    },
  });

  const [startLab] = useMutation(START_LAB, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      const responseType = data?.Assessments_v2_startLab?.__typename;

      switch (responseType) {
        case 'Assessments_AttemptAlreadyGraded':
          showErrorMessage('This exam has been completed');
          history.push(`/${assessmentId}/result/${attemptId}`);
          break;
        case 'Assessments_StartLabError':
          showErrorMessage();
          break;
        default:
          refetch();
          return;
      }
    },
  });

  const [fetchCredentials, { data: myLabData }] = useLazyQuery(MY_LAB_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const status = data?.LabKeep_myLab?.instance?.status;

      if (data?.LabKeep_myLab?.instance?.uuid !== currentLabInstanceId) {
        return errorMessage('Oops, something went wrong! Please try again', 'lab-page');
      }

      if (status !== LAB_STATUS.READY) {
        return setTimeout(fetchCredentials, 8000);
      }

      setIsLabReady(true);
    },
    onError() {
      errorMessage('Oops, something went wrong! Please try again', 'lab-page');
    },
  });

  const currentAttempt = get(attempt, 'Assessments_v2_attempt');
  const examTitle = get(currentAttempt, 'title', '');
  const currentLabIndex = get(currentAttempt, 'currentLabIndex', 0);
  const labs = get(currentAttempt, 'labs', []);
  const currentLab = get(labs, `[${currentLabIndex}]`, {});
  const currentLabInstanceId = get(currentLab, 'instanceUuid', '');
  const description = get(currentLab, 'description', '');
  const instructions = get(currentLab, 'instructions');
  const diagramUrl = get(currentLab, 'diagramUrl');
  const objectives = get(currentLab, 'tasks', []);
  const expandedObjectives = objectives.map((_, index) => index.toString());

  const hasNextLab = currentLabIndex < labs.length - 1;

  const credentials = get(myLabData, 'LabKeep_myLab.instance.remoteDetails.credentials[0].credentials[0]', {});

  const trackLabEvent = useCallback(
    (message) => {
      track(message, {
        assessmentId,
        attemptId,
        labId: currentLab?.id,
        labInstanceId: currentLabInstanceId,
      });
    },
    [assessmentId, attemptId, currentLab, currentLabInstanceId]
  );

  useEffect(() => {
    const onWindowClose = () => {
      if (currentLab && currentLabInstanceId) {
        trackLabEvent('Practice Exam Lab Closed');
      }
    };

    window.addEventListener('beforeunload', onWindowClose);
  }, [currentLab, currentLabInstanceId, trackLabEvent]);

  const onSubmit = () => {
    const currentAttempt = get(attempt, 'Assessments_v2_attempt');
    const currentLabIndex = get(currentAttempt, 'currentLabIndex', 0);
    const labs = get(currentAttempt, 'labs', []);

    const hasNextLab = currentLabIndex < labs.length - 1;
    if (hasNextLab && !hasTimerExpired) {
      setIsSubmitPending(true);
      setIsLabReady(false);
    } else {
      setGrading(true);
    }

    submitLab();
  };

  const objectivesList = objectives.map((objective, index) => {
    return (
      <Objective header={objective.name} key={index}>
        <Markdown text={objective.explanation} fontSize="14px" />
      </Objective>
    );
  });

  return (
    <>
      <Helmet>
        <title>{`Practice Exam – ${examTitle} – A Cloud Guru`}</title>
      </Helmet>
      {currentAttempt && (
        <Overlay show={grading}>
          <Background>
            <Header examName={examTitle} coverImageUrl={currentAttempt.coverImageUrl}>
              <Timer
                endTime={currentAttempt.assessmentEndDate}
                onExpire={() => {
                  setHasTimerExpired(true);
                  trackLabEvent('Practice Exam Lab Timed Out');
                  onSubmit();
                }}
              />
            </Header>
            <LabContainer>
              <MainContainer>
                <Card data-testid="introduction-section" title="INTRODUCTION" loading={loadingAttempt}>
                  <Skeleton paragraph={true} loading={loadingAttempt}>
                    <Markdown text={description} fontSize="14px" />
                  </Skeleton>
                </Card>
                {instructions && (
                  <Card data-testid="prerequisites-section" title="PREREQUISITES" loading={loadingAttempt}>
                    <Skeleton loading={loadingAttempt} paragraph={true}>
                      <Markdown text={instructions} fontSize="14px" />
                    </Skeleton>
                  </Card>
                )}
                <Card data-testid="objectives-section" title="OBJECTIVES" loading={loadingAttempt}>
                  <Skeleton title="true" loading={loadingAttempt}>
                    {objectives && objectives.length ? (
                      <Collapse
                        bordered={false}
                        data-testid="objectives-dropdown"
                        expandIconPosition="right"
                        defaultActiveKey={expandedObjectives}
                        ghost
                      >
                        {objectivesList}
                      </Collapse>
                    ) : (
                      <></>
                    )}
                  </Skeleton>
                </Card>
              </MainContainer>
              <Aside>
                <Tools diagramUrl={diagramUrl} loading={!isLabReady} />
                <Credentials
                  isLoading={!isLabReady}
                  username={credentials?.username}
                  password={credentials?.password}
                  labUrl={credentials?.url}
                  onCopyCredentials={() => trackLabEvent('Practice Exam Lab Credentials Copied')}
                  onHelpLinkClicked={() => trackLabEvent('Practice Exam Lab Help Page Clicked')}
                />
                <SubmitLab
                  disabled={!isLabReady}
                  loading={isSubmitPending || grading}
                  onSubmit={onSubmit}
                  hasNextLab={hasNextLab}
                />
              </Aside>
            </LabContainer>
          </Background>
        </Overlay>
      )}
    </>
  );
};

const LabContainer = styled(Container)`
  width: 100%;
  align-items: center;
  margin: 48px 0px 211px 0px;
  @media (min-width: ${breakpoints.xl}) {
    align-items: flex-start;
    flex-direction: row;
  }
`;

const MainContainer = styled.main`
  width: 728px;
  @media (min-width: ${breakpoints.xl}) {
    margin-right: 51px;
  }
`;

const Objective = styled(Panel)`
  &.ant-collapse-item {
    border-bottom: 1px solid ${colours.lightGrey500};
    font-weight: 600;
    font-size: 16px;
    color: ${colours.darkGrey900};

    .ant-collapse-header {
      padding-bottom: 8px;
      padding-top: 8px;
      padding: 8px 32px;
      padding-left: 0;
      display: flex;
      align-items: center;
    }

    .ant-collapse-content {
      font-weight: 400;
      padding: 0;
    }

    .ant-collapse-ghost & .ant-collapse-content-box {
      padding: 4px 32px 16px 0;
    }
  }
`;

const Aside = styled.aside`
  width: 346px;
`;

export { Lab };
