import React from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import { Box, colours } from '@a-cloud-guru/rainbow-ui';
import { ReactComponent as TickIcon } from './tick.svg';

const MessageContainer = styled(Box)`
  display: flex;
  padding: 16px 64px;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
`;

const MessageText = styled.span`
  padding-left: 8px;
`;

const errorMessage = (description, namespace = 'common') => {
  message.open({
    content: (
      <MessageContainer bg={colours.red700} color={colours.white} data-testid={`shared-error-message-${namespace}`}>
        {description}
      </MessageContainer>
    ),
  });
};

const successMessage = (description, namespace = 'common') => {
  message.open({
    content: (
      <MessageContainer color={colours.darkGrey900} data-testid={`shared-error-message-${namespace}`}>
        <TickIcon /> <MessageText>{description}</MessageText>
      </MessageContainer>
    ),
  });
};

export { errorMessage, successMessage };
