const CONFIG_ENV = process.env.REACT_APP_ACG_ENV || process.env.ACG_ENV;

const devConfig = {
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  LEARN_URL: 'https://staging-learn.acloud.guru',
  ACG_PRACTICE_EXAM_DOMAIN: 'http://localhost:3000',
  GRAPHQL_API_ENDPOINT: CONFIG_ENV === 'local' ? 'http://localhost:4000' : 'https://test-api.acloud.guru/bff/graphql',
  BUGSNAG_RELEASE_STAGE: 'development',
  ACG_DOMAIN: 'https://test-auto.acloud.guru',
  ACG_BUSINESS_DOMAIN: 'https://staging-business.acloud.guru',
  SEGMENT_API_KEY: 'IKoF6Brkpj0xbiBc4AsovImoTDIYxWXd',
  PS_LOGOUT_URL: 'http://app-stage.pluralsight.com/id/signout',
};

export { devConfig };
