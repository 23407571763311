import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';

const SectionHeading = styled.h4`
  color: ${colours.orange};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
  margin: 0px;
  min-height: 19px;
`;

export { SectionHeading };
