import { Modal as RainbowModal, colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from './images/close.svg';

const Modal = ({ title, footer, visible, onCancel, wrapClassName, children }) => {
  return (
    <StyledModal
      className={wrapClassName}
      title={<Title text={title} />}
      visible={visible}
      onCancel={onCancel}
      closeIcon={<CloseIcon data-testid="closeIcon" />}
      footer={footer}
    >
      {children}
    </StyledModal>
  );
};

const StyledModal = styled(RainbowModal)`
  .ant-modal-header {
    padding: 24px 24px 16px;
    border-bottom: 0;

    h1 {
      margin: 0;
    }
  }

  .ant-modal-body {
    padding: 0 24px;
  }

  .ant-modal-footer {
    border-top: 0;
    padding: 24px;
  }

  .ant-modal-close-x {
    width: 63px;
    height: 63px;
    line-height: 63px;
    margin: 5px;
  }

  p {
    font-size: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Title = ({ text }) => (
  <div>
    <h1>{text}</h1>
    <DividingLine />
  </div>
);

const DividingLine = styled.div`
  height: 1px;
  background: ${colours.lightGrey500};
  margin-top: 16px;
`;

export { Modal };
