const commonConfig = {
  BUILD_NUMBER: process.env.REACT_APP_BUILD_NUMBER || '0',
  BUILD_COMMIT: process.env.REACT_APP_BUILD_COMMIT || '0',
  STAGE: process.env.REACT_APP_ACG_ENV,
  BUGSNAG_API_KEY: '1aae871c7784490da501f7a380ac91e7',
  SPLIT_IO_API_KEY: 's8cnf1sgce1m4e3cg62o9mune7dde7l3t5k4',
  PLURALSIGHT_LIBRARY_URL: 'https://app-stage.pluralsight.com/library',
};

export { commonConfig };
