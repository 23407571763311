import { gql } from '@apollo/client';

const CHECK_OBJECTIVES = gql`
  query checkObjectives($input: Assessment_AttemptInput!, $contentType: Assessments_AccessPermissions_ContentType!) {
    Assessments_v2_attempt(input: $input, contentType: $contentType) {
      numberOfLabs
      currentLabIndex
      labs {
        id
        tasks {
          id
          isPassed
        }
      }
    }
  }
`;

export { CHECK_OBJECTIVES };
