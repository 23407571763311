import React from 'react';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '../images/arrow.svg';
import { StyledLink } from './StyledLink';

const PassedLink = ({ url, score, disabled }) => {
  if (disabled) {
    return <DisabledSpan> Passed ({score}%) </DisabledSpan>;
  }

  return (
    <StyledLink href={url} color={colours.green700} hoverColor={colours.green500}>
      Passed ({score}%) <ArrowIcon />
    </StyledLink>
  );
};

const ArrowIcon = styled(Arrow)`
  margin-left: 4px;
`;

const DisabledSpan = styled.span`
  font-weight: 600;
  color: ${colours.darkGrey50};
`;

export { PassedLink };
