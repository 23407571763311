import React from 'react';
import qs from 'qs';

import { authService } from '../../services/auth';
import { LoadingScreen } from '../../components/LoadingScreen';

const Login = () => {
  const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  authService.login({ state: authService.encodeState(queryString) });

  return <LoadingScreen />;
};

export { Login };
