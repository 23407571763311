import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import Bugsnag from '@bugsnag/js';
import { get } from 'lodash';
import { getConfig } from '../config/get-config';
import { authService } from '../services/auth';
import { redirectLogin } from './redirectLogin';

const { GRAPHQL_API_ENDPOINT, STAGE, BUILD_NUMBER, BUILD_COMMIT } = getConfig();

const getCorrelationIds = (context) => {
  try {
    const headers = get(context, `response.headers`);
    return {
      'x-correlation-id': headers.get('x-correlation-id'),
      'x-correlation-user-id': headers.get('x-correlation-user-id'),
      'x-correlation-org-id': headers.get('x-correlation-org-id'),
      'x-correlation-package-id': headers.get('x-correlation-package-id'),
    };
  } catch (e) {
    return '';
  }
};

const getGraphqlDetail = (operation) => {
  const queryBody = get(operation, 'query.loc.source.body');
  const variables = get(operation, 'variables');
  const operationName = get(operation, 'operationName');

  return {
    queryBody,
    variables,
    operationName,
  };
};

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const correlationIds = getCorrelationIds(operation.getContext());
    const correlationId = get(correlationIds, 'x-correlation-id');
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      const errorMessage = `[GraphQL error]: Message: ${message}, Path: ${path}, CorrelationId: ${correlationId}`;
      console.log(errorMessage);
      Bugsnag.notify(new Error(errorMessage), (event) => {
        event.addMetadata('GraphQL', {
          message,
          locations,
          path,
          extensions,
          ...correlationIds,
          ...getGraphqlDetail(operation),
        });
      });
    });
  }

  if (networkError) {
    const errorMessage = `[Network error]: ${networkError}`;
    console.log(errorMessage);
    Bugsnag.notify(new Error(errorMessage), (event) => {
      event.addMetadata('GraphQL', {
        ...getGraphqlDetail(operation),
      });
    });
  }
});

const httpLink = createHttpLink({
  uri: GRAPHQL_API_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  return authService
    .getToken()
    .then((token) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : 'Bearer ',
          'apollographql-client-name': `practice-exam-${STAGE}`,
          'apollographql-client-version': `${BUILD_NUMBER}_${BUILD_COMMIT}`,
        },
      };
    })
    .catch(() => {
      redirectLogin();
    });
});

const getClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export { getClient };
