import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';

const CallOut = ({ title, bodyText, icon, ...rest }) => {
  return (
    <Container {...rest}>
      <div>{icon}</div>
      <Body>
        <Title>{title}</Title>
        <BodyText>{bodyText}</BodyText>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${colours.lightGrey300};
  padding: 1rem;
  border-radius: 8px;
  color: ${colours.darkGrey900};
  display: flex;
  margin: 24px 0;
`;

const Body = styled.span`
  margin-left: 10px;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
`;

const BodyText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
`;

export { CallOut };
