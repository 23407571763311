import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Card, breakpoints, colours } from '@a-cloud-guru/rainbow-ui';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { differenceInMilliseconds } from 'date-fns';
import { useQuery } from '@apollo/client';

import { errorMessage } from '../../components/Message/Message';
import { formatTime } from './../../lib/util';
import { Header } from '../../components/Header/Header';
import { Container, Background } from '../../components/Layout';
import { LoadingScreen } from '../../components/LoadingScreen';
import { ATTEMPT_RESULT } from './../../lib/sharedGraphql';
import { DomainBreakdown } from './DomainBreakdown';
import { QuestionDomain } from './QuestionDomain';
import { LabDomain } from './LabDomain';
import { RateExam } from './RateExam';
import { QuickTips } from './QuickTips';
import { getConfig } from '../../config/get-config';
import { BackLink } from '../../components/BackLink';

const { LEARN_URL } = getConfig();

const Result = () => {
  const history = useHistory();
  const showErrorMessage = (message) =>
    errorMessage(message || 'Oops, something went wrong! Please try again.', 'result-page');
  const { attemptId, assessmentId } = useParams();

  const { data, loading, error } = useQuery(ATTEMPT_RESULT.QUERY, {
    variables: {
      input: {
        id: attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    onError() {
      showErrorMessage('Oops! Something went wrong fetching your results. Please try again.', 'result-page');
    },
    onCompleted(data) {
      if (!get(data, 'Assessments_v2_attemptResult')) {
        showErrorMessage(
          'You do not have permission to perform this action. Check your subscription and try again later.'
        );
        return;
      }
    },
  });

  if (loading || error) {
    return <LoadingScreen />;
  }

  if (get(data, 'Assessments_v2_attemptResult') === null) {
    history.push(`/${assessmentId}`);
    return <></>;
  }

  const questionDomainGrades = get(data, 'Assessments_v2_attemptResult.domainGradeBreakdown', []).filter(
    (grade) => grade.__typename === 'Assessments_QuestionDomainGrade'
  );
  const labDomainGrade = get(data, 'Assessments_v2_attemptResult.domainGradeBreakdown', []).filter(
    (grade) => grade.__typename === 'Assessments_LabDomainGrade'
  );

  const result = get(data, 'Assessments_v2_attemptResult', {});
  const numberOfLabs = get(result, 'numberOfLabs');
  const examTitle = get(result, 'title', '');
  const milliseconds = differenceInMilliseconds(new Date(result.gradedDate), new Date(result.createdDate));
  const timeElapsed = milliseconds ? formatTime(milliseconds) : '00:00';
  return (
    <>
      <Helmet>
        <title>{`Practice Exam – ${examTitle} – A Cloud Guru`}</title>
      </Helmet>
      <Background data-testid="result-page-base">
        <Header examName={get(result, 'title', '')} coverImageUrl={get(result, 'coverImageUrl')}>
          <BackLink
            url={`${LEARN_URL}/dashboard`}
            linkText="Back to dashboard"
            data-testid="result-page-dashboard-button"
          />
        </Header>
        <ResultContainer>
          <TitleCard>
            <GhostPercent data-testid="result-page-ghost-percent">{get(result, 'grade.score', '0')}%</GhostPercent>
            <TitlePercent data-testid="result-page-title-percent">{`You scored ${get(
              result,
              'grade.score',
              '0'
            )}%`}</TitlePercent>
            <TitleInfo data-testid="result-page-title-info">
              <TitleSummary>
                You answered{' '}
                <strong data-testid="result-page-total-correct">{get(result, 'grade.questionsCorrect', '0')}</strong> of{' '}
                <strong data-testid="result-page-total-questions">{get(result, 'grade.totalQuestions', '0')}</strong>{' '}
                questions correctly in <strong data-testid="result-page-time-elapsed">{timeElapsed}</strong>
              </TitleSummary>
              {numberOfLabs > 0 && (
                <TitleSummary>
                  You got <strong>{get(result, 'grade.passedObjectives', '0')}</strong> of{' '}
                  <strong>{get(result, 'grade.totalObjectives', '0')}</strong> lab objectives correct
                </TitleSummary>
              )}
            </TitleInfo>
            <RateExamContainer data-testid="result-page-rate-practice-exam">
              <RateExam />
            </RateExamContainer>
            <QuickTips
              gradedDate={get(result, 'gradedDate', 0)}
              endDate={get(result, 'assessmentEndDate', 0)}
              questions={get(result, 'questions', [])}
              passed={get(result, 'grade.didPass', false)}
              data-testid="quick-tip"
            />
          </TitleCard>
          <DomainBreakdown
            domains={get(data, 'Assessments_v2_attemptResult.domainGradeBreakdown', [])}
            loading={loading}
          />

          {questionDomainGrades.map((domain, index) => (
            <QuestionDomain
              data-testid={`domain-details-${index}`}
              key={index}
              domain={domain}
              questions={get(data, 'Assessments_v2_attemptResult.questions', [])}
              loading={loading}
              index={index}
              assessmentId={assessmentId}
            />
          ))}

          {labDomainGrade.map((domain) => (
            <LabDomain
              data-testid={`lab-domain-detail`}
              domain={domain}
              loading={loading}
              labs={get(data, 'Assessments_v2_attemptResult.labs', [])}
              assessmentId={assessmentId}
            />
          ))}
        </ResultContainer>
      </Background>
    </>
  );
};

const TitleInfo = styled.div`
  margin-bottom: 80px;
`;

const TitlePercent = styled.h1`
  font-family: sans-serif;
  margin-top: 80px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 66px;
  color: ${colours.darkGrey900};
`;

const TitleSummary = styled.p`
  font-family: sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: ${colours.darkGrey900};
`;

const ResultContainer = styled(Container)`
  width: 1124px;
  margin: 18px auto;
  max-width: 88%;
`;

const RateExamContainer = styled.div`
  display: flex;
  justify-content: center;

  > div:first-child {
    min-width: 202px;
  }
`;

const GhostPercent = styled.h1`
  font-family: sans-serif;
  margin-top: 7px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 160px;
  opacity: 0.05;
  position: absolute;
  text-align: center;
  width: 100%;
  pointer-events: none;

  @media (max-width: ${breakpoints.sm}) {
    font-size: 135px;
  }
`;

const TitleCard = styled(Card)`
  text-align: center;
  margin: 0 auto;
  box-shadow: 0px 2px 14px rgba(173, 186, 208, 0.303431);
  border-radius: 5px;

  .ant-card-body {
    padding: 0;
  }
`;

export { Result };
