import { gql } from '@apollo/client';
import { errorMessage } from './../components/Message/Message';

const CURRENT_ATTEMPT = {
  QUERY: gql`
    query getAttempt($input: Assessment_AttemptInput!, $contentType: Assessments_AccessPermissions_ContentType!) {
      Assessments_v2_attempt(input: $input, contentType: $contentType) {
        id
        title
        status
        description
        numberOfQuestions
        numberOfLabs
        assessmentEndDate
        currentQuestionIndex
        currentLabIndex
        coverImageUrl
        labs {
          id
          instanceUuid
          description
          instructions
          diagramUrl
          tasks {
            id
            name
            explanation
          }
        }
        questions {
          id
          questionText
          type
          possibleCorrect
          submitted
          flagged
          selectedChoiceIds
          order
          choices {
            id
            text
          }
        }
      }
    }
  `,
};

const resultFields = `
  id
  title
  coverImageUrl
  assessmentEndDate
  gradedDate
  createdDate
  numberOfLabs
  grade {
    score
    questionsCorrect
    totalQuestions
    totalObjectives
    passedObjectives
    didPass
  }
  domainGradeBreakdown {
    __typename
    domainName
    domainDescription
    score
    order
    weight
    ...on Assessments_QuestionDomainGrade {
      questionPoolId
    }
    ...on Assessments_LabDomainGrade {
      passedObjectives
      objectiveCount
    }
  }
  questions {
    id
    questionId
    questionText
    type
    possibleCorrect
    submitted
    flagged
    selectedChoiceIds
    order
    poolId
    choices {
      id
      text
      correctAnswer
      explanation
    }
    grade {
      id
      isCorrect
      correctAnswers {
        id
        text
        correctAnswer
        explanation
      }
      incorrectSelectedAnswers {
        id
        text
        correctAnswer
        explanation
      }
    }
  }
  labs {
    id
    description
    instanceUuid
    minPassingObjectiveCount
    tasks {
      id
      name
      isPassed
      assertionChecks {
        assertionCheckUuid
        status
        failureFeedback
      }
    }

  }
`;

const ATTEMPT_RESULT = {
  QUERY: gql`
    query Assessments_v2_attemptResult($input: Assessment_AttemptResultInput!, $contentType: Assessments_AccessPermissions_ContentType!) {
      Assessments_v2_attemptResult(input: $input, contentType: $contentType) {
        ${resultFields}
      }
    }
  `,
};

const SUBMIT_EXAM = {
  MUTATION: gql`
    mutation Assessments_gradeAttempt($input: Assessments_GradeAttemptInput!) {
      Assessments_gradeAttempt(input: $input) {
        ... on Assessments_GradedAttempt {
          ${resultFields}
        }
      }
    }
  `,
  options: ({ assessmentId, attemptId, history }) => ({
    variables: { input: { attemptId } },
    update(cache, { data }) {
      cache.writeQuery({
        query: CURRENT_ATTEMPT.QUERY,
        variables: {
          input: {
            id: attemptId,
            assessmentId,
          },
          contentType: 'PRACTICE_EXAM',
        },
        data: {
          Assessments_v2_attempt: data.Assessments_gradeAttempt,
        },
      });
      cache.writeQuery({
        query: ATTEMPT_RESULT.QUERY,
        variables: {
          input: {
            id: attemptId,
            assessmentId,
          },
          contentType: 'PRACTICE_EXAM',
        },
        data: {
          Assessments_v2_attemptResult: data.Assessments_gradeAttempt,
        },
      });
    },
    onError() {
      errorMessage('Oops! Something went wrong submitting your results. Please try again.', 'exam-page');
    },
    onCompleted() {
      history.push(`/${assessmentId}/result/${attemptId}`);
    },
  }),
};

const UPDATE_QUESTION_INDEX = {
  MUTATION: gql`
    mutation Assessments_v2_updateQuestionIndex(
      $input: Assessments_UpdateQuestionIndexInput!
      $contentType: Assessments_AccessPermissions_ContentType!
    ) {
      Assessments_v2_updateQuestionIndex(input: $input, contentType: $contentType) {
        ... on Assessments_Attempt {
          id
          title
          numberOfQuestions
          assessmentEndDate
          currentQuestionIndex
          coverImageUrl
        }
        ... on Assessments_AttemptAlreadyGraded {
          message
        }
      }
    }
  `,
  options: ({ questionNumber, attempt, assessmentId }) => ({
    variables: {
      input: {
        attemptId: attempt.id,
        questionIndex: questionNumber,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    optimisticResponse: {
      __typename: 'Mutation',
      Assessments_v2_updateQuestionIndex: {
        ...attempt,
        currentQuestionIndex: questionNumber,
      },
    },
  }),
};

export { SUBMIT_EXAM, ATTEMPT_RESULT, CURRENT_ATTEMPT, UPDATE_QUESTION_INDEX };
