import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { breakpoints, colours } from '@a-cloud-guru/rainbow-ui';
import { Card } from '../../components/Card/Card';
import { Markdown } from '../../components/Markdown';
import { QuestionChoiceResult } from './QuestionChoiceResult';
import { RateQuestion } from './RateQuestion';

const QuestionResults = ({ question, domainIndex, assessmentId }) => {
  const submitted = get(question, 'submitted');
  const [revealAnswer, setRevealAnswer] = useState(submitted === true);
  const selectedChoiceIds = get(question, 'selectedChoiceIds', []) || [];

  useEffect(() => {
    const submitted = get(question, 'submitted');
    setRevealAnswer(submitted === true);
  }, [question]);

  return (
    <QuestionResultsCard
      title={`Question ${question.order + 1}`}
      data-testid={`domain-${domainIndex}-question-results`}
    >
      <Question data-testid={`domain-${domainIndex}-question-results-question-text`}>
        <Markdown text={question.questionText} />
      </Question>
      {get(question, 'choices', []).map((choice, index) => (
        <QuestionChoiceResult
          key={index}
          domainIndex={domainIndex}
          choiceIndex={index}
          choice={choice}
          selectedChoiceIds={selectedChoiceIds}
          revealAnswer={revealAnswer}
        />
      ))}
      {!revealAnswer && (
        <UnSubmittedQuestionText data-testid="question-explanation-unsubmitted-question-text">
          You didn't answer this question.
          <RevealAnswer data-testid="question-explanation-reveal-answer-button" onClick={() => setRevealAnswer(true)}>
            {' '}
            Reveal answer?
          </RevealAnswer>
        </UnSubmittedQuestionText>
      )}
      <Footer data-testid={`domain-${domainIndex}-question-results-rate-question-buttons-container`}>
        <RateQuestion questionId={question.questionId} assessmentId={assessmentId} />
      </Footer>
    </QuestionResultsCard>
  );
};

// Height is being set only temporarily so we can dial in the layout
const QuestionResultsCard = styled(Card)`
  width: 65%;
  height: fit-content;
  box-shadow: 0px 2px 14px rgba(173, 186, 208, 0.303431);
  border-radius: 5px;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-top: 12px;
  }
`;

const Question = styled.div`
  margin-bottom: 24px;
  font-weight: 500;
  line-height: 24px;
`;

const Footer = styled.footer`
  margin-top: 28px;
`;

const UnSubmittedQuestionText = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
`;

const RevealAnswer = styled(UnSubmittedQuestionText)`
  cursor: pointer;
  color: ${colours.blue700};
  font-weight: 500;
`;

export { QuestionResults };
