import { schema } from './schema';
import { commonConfig } from './common.config';
import { devConfig } from './dev.config';
import { testConfig } from './test.config';
import { productionConfig } from './production.config';

const configs = {
  dev: devConfig,
  local: devConfig,
  cypress: devConfig,
  test: testConfig,
  production: productionConfig,
};

const getConfigForEnvironment = (environment) => {
  const currentConfig = configs[environment];

  if (!currentConfig) {
    throw new Error('Could not find config file');
  }

  const config = { ...commonConfig, ...currentConfig };

  const { error, value } = schema.validate(config);

  if (error) {
    throw error;
  }

  return value;
};

export { getConfigForEnvironment };
