import React from 'react';
import styled from 'styled-components';
import { colours, breakpoints } from '@a-cloud-guru/rainbow-ui';
import { SectionHeading } from '../../components/SectionHeading';
import { NavigationItem } from '../../components/NavigationItem/NavigationItem';

const DomainLabNavigation = ({ labs, selectedLab, setSelectedLab }) => {
  const domainLabs = labs.map((lab, index) => {
    return (
      <NavigationItem
        key={index}
        isCorrect={lab.isPassed}
        isSubmitted={lab.isSubmitted}
        isActive={index === selectedLab}
        handleClick={() => setSelectedLab(index)}
      >
        {lab.order + 1}
      </NavigationItem>
    );
  });

  return (
    <div data-testid={'lab-domain-navigation'}>
      <LabsHeading>
        <SectionHeading>Labs</SectionHeading>
        <Divider />
      </LabsHeading>
      <NavigationButtonWrapper data-testid={'lab-domain-navigation-buttons'}>{domainLabs}</NavigationButtonWrapper>
    </div>
  );
};

const LabsHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const Divider = styled.div`
  width: 207px;
  height: 1px;
  background-color: ${colours.lightGrey500};
  margin-left: 15px;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const NavigationButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export { DomainLabNavigation };
