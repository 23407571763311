import { gql } from '@apollo/client';

const STORE_LAB_GRADE = gql`
  mutation Assessments_storeLabGrade(
    $input: Assessments_StoreLabGradeInput!
    $contentType: Assessments_AccessPermissions_ContentType!
  ) {
    Assessments_v2_storeLabGrade(input: $input, contentType: $contentType) {
      ... on Assessments_LabGraded {
        isGraded
      }
      ... on Assessments_AttemptNotFound {
        message
      }
      ... on Assessments_NoActiveLabInstance {
        message
      }
      ... on Assessments_GradingSessionNotFound {
        message
      }
      ... on Assessments_AttemptAlreadyGraded {
        message
      }
      ... on Assessments_StoreLabGradeError {
        message
      }
    }
  }
`;

export { STORE_LAB_GRADE };
