import React, { useState, useRef, useEffect } from 'react';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { rgba } from 'polished';

const ShowMore = ({ isExpanded, setIsExpanded }) => (
  <>
    {!isExpanded && <Ellipsis role="presentation">...</Ellipsis>}
    <Toggle
      type="button"
      onClick={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      {isExpanded ? 'Less' : 'More'}
    </Toggle>
  </>
);

const TextSummary = ({ numberOfLines = 2, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentComponent = useRef(null);

  useEffect(() => {
    const checkOverflow = ({ clientHeight, scrollHeight }) => {
      return scrollHeight > clientHeight;
    };
    setIsOverflowing(checkOverflow(contentComponent.current));
  }, [setIsOverflowing]);
  return (
    <>
      <Content ref={contentComponent} numberOfLines={numberOfLines} isExpanded={isExpanded}>
        {children}
      </Content>
      {isOverflowing && ShowMore({ isExpanded, setIsExpanded })}
    </>
  );
};

const Ellipsis = styled.div`
  position: relative;
  &::before {
    content: '';
    display: block;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.7136029411764706) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    height: 30px;
    top: -20px;
    width: 100%;
    left: 0;
  }
`;

const Content = styled.div`
  max-height: ${({ isExpanded, numberOfLines }) => (isExpanded ? 'none' : `calc(1.5rem * ${numberOfLines})`)};
  overflow: hidden;
`;

const Toggle = styled.button`
  background: none;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: -10px;
  font-size: 16px;
  color: ${colours.darkGrey600};

  &:hover {
    border-color: ${colours.lightGrey500};
    border-radius: 3px;
  }

  &:focus {
    outline: none;
    border-radius: 4px;
    box-shadow: ${rgba(colours.blue500, 1)} 0px 0px 3px 1px;
  }
`;

export { TextSummary };
