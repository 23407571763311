import React from 'react';
import styled from 'styled-components';
import { colours, Skeleton } from '@a-cloud-guru/rainbow-ui';
import { SectionHeading } from '../../../components/SectionHeading';
import { AttemptItem } from './AttemptItem';

const AttemptHistory = ({ assessmentId, attempts, loading, hasPracticeExamAccess, activeLabInstanceUuid }) => {
  const attemptList = () => {
    if (!attempts || (Array.isArray(attempts) && attempts.length === 0)) {
      return <Paragraph data-testid="start-page-empty-attempts">You haven’t attempted this exam yet.</Paragraph>;
    }
    return attempts?.map((attempt, index) => (
      <AttemptItem
        key={index}
        attemptId={attempt.id}
        status={attempt.status}
        score={attempt.grade?.score || 0}
        isMigrated={attempt.migrated}
        createdDate={attempt.createdDate}
        endDate={attempt.assessmentEndDate}
        assessmentId={assessmentId}
        index={index}
        hasPracticeExamAccess={hasPracticeExamAccess}
        activeLabInstanceUuid={activeLabInstanceUuid}
        labs={attempt.labs}
      />
    ));
  };

  return (
    <AttemptHistoryWrapper>
      <SectionHeading data-testid="start-page-attempt-history">Attempt History</SectionHeading>
      <Skeleton loading={loading} title={false}>
        {attemptList()}
      </Skeleton>
    </AttemptHistoryWrapper>
  );
};

const AttemptHistoryWrapper = styled.section`
  margin: 24px 0;
`;

const Paragraph = styled.p`
  color: ${({ disabled }) => (disabled ? colours.darkGrey50 : colours.darkGrey900)};
  margin: 0px;
  width: 70%;
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
`;

export { AttemptHistory };
