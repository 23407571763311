const identify = (userId, traits) => {
  if (!window.analytics || !userId) {
    return;
  }

  window.analytics.identify(userId, traits);
};

const track = (eventName, properties) => {
  if (!window.analytics || !eventName) {
    return;
  }

  window.analytics.track(eventName, properties);
};

export { identify, track };
