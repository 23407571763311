import get from 'lodash/get';
import { useQuery, useMutation, gql } from '@apollo/client';
import { errorMessage } from '../components/Message/Message';

const RATING_QUERY = gql`
  query Ratings_rating($category: String!, $entityId: String!) {
    Ratings_rating(category: $category, entityId: $entityId) {
      id
      vote
      comment
    }
  }
`;

const CREATE_RATING_MUTATION = gql`
  mutation Ratings_createRating($input: Ratings_CreateRatingInput!) {
    Ratings_createRating(input: $input) {
      id
      vote
      comment
    }
  }
`;

const showErrorMessage = () => errorMessage('Oops, something went wrong! Please try again.', 'result-page');

const useRating = ({ category, entityId }) => {
  const { data, loading } = useQuery(RATING_QUERY, {
    variables: {
      category,
      entityId,
    },
    onError() {
      showErrorMessage();
    },
  });
  const [createRating, { loading: voting }] = useMutation(CREATE_RATING_MUTATION, {
    onError() {
      showErrorMessage();
    },
  });

  const getOptions = ({ vote, comment }) => ({
    variables: {
      input: {
        vote,
        category,
        entityId,
        comment,
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      Ratings_createRating: {
        __typename: 'Ratings_Rating',
        id: entityId,
        vote,
        comment,
      },
    },
  });
  return {
    loading,
    voting,
    data: get(data, 'Ratings_rating'),
    upvote: (comment) => createRating(getOptions({ vote: 'UPVOTE', comment })),
    downvote: (comment) => createRating(getOptions({ vote: 'DOWNVOTE', comment })),
  };
};

export { useRating, RATING_QUERY, CREATE_RATING_MUTATION };
