import React from 'react';
import styled from 'styled-components';
import { colours, Skeleton } from '@a-cloud-guru/rainbow-ui';
import { SectionHeading } from '../../components/SectionHeading';

const totalLabsWeight = (labs) => {
  return labs.reduce((total, current) => total + current.weight, 0);
};

const DomainRow = ({ label, weight, index }) => (
  <Row>
    <span data-testid={`domain-name-${index}`}>{label}</span>
    <span data-testid={`domain-weight-${index}`}>{weight}%</span>
  </Row>
);

const Domains = ({ questionPools, labs, loading }) => {
  const domainList = questionPools?.map((questionPool, index) => {
    return <DomainRow key={index} index={index} label={questionPool.domainName} weight={questionPool.weight} />;
  });

  return (
    <DomainContainer>
      <SectionHeading>Domain Breakdown</SectionHeading>
      <Skeleton loading={loading} title={false}>
        {domainList}
        {labs?.length > 0 && <DomainRow index={domainList.length} label="Labs" weight={totalLabsWeight(labs)} />}
      </Skeleton>
    </DomainContainer>
  );
};

const DomainContainer = styled.section`
  margin-bottom: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 1px solid ${colours.lightGrey500};
  padding-bottom: 8px;
  margin: 8px 0;
`;

export { Domains };
