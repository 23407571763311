import React, { useEffect, useState } from 'react';
import { SplitIOProvider } from 'splitio-react';
import { getConfig } from '../config/get-config';
import { useAuth } from '@a-cloud-guru/react-auth';
import { isCypress } from '../lib/isCypress';

const { SPLIT_IO_API_KEY } = getConfig();

const PRACTICE_EXAM_SPLITS = ['CONTENT_ACCESS'];

const SplitIO = ({ children }) => {
  const { viewer } = useAuth();
  const [userId, setUserId] = useState();
  const [organisationId, setOrganisationId] = useState();

  useEffect(() => {
    if (viewer) {
      setUserId(viewer.id);
      setOrganisationId(viewer.organisationId);
    }
  }, [viewer, setUserId, setOrganisationId]);

  return isCypress() || PRACTICE_EXAM_SPLITS.length === 0 ? (
    <>{children}</>
  ) : (
    <SplitIOProvider
      splitKey={userId}
      authorizationKey={SPLIT_IO_API_KEY}
      attributes={{
        userId,
        organisationId,
      }}
      splits={[...PRACTICE_EXAM_SPLITS]}
    >
      {children}
    </SplitIOProvider>
  );
};

export { SplitIO };
