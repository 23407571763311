import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getConfig } from '../config/get-config';
import { isCypress } from './isCypress';

const { BUGSNAG_API_KEY, BUGSNAG_RELEASE_STAGE, BUILD_NUMBER, BUILD_COMMIT } = getConfig();

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  appVersion: `${BUILD_NUMBER}_${BUILD_COMMIT}`,
  releaseStage: BUGSNAG_RELEASE_STAGE,
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    // Make sure FullStory object exists.
    if (window.FS && window.FS.getCurrentSessionURL) {
      event.addMetadata('fullstory', {
        urlAtTime: window.FS.getCurrentSessionURL(true),
      });
    }
  },
});

const ErrorBoundary = isCypress() ? React.Fragment : Bugsnag.getPlugin('react').createErrorBoundary(React);

export { ErrorBoundary, Bugsnag };
