import Joi from '@hapi/joi';

const schema = Joi.object()
  .keys({
    AUTH0_CLIENT_ID: Joi.string().required(),
    AUTH0_DOMAIN: Joi.string().required(),
    AUTH0_CUSTOM_DOMAIN: Joi.string().required(),
    AUTH0_API_AUDIENCE: Joi.string().required(),
    ACG_PRACTICE_EXAM_DOMAIN: Joi.string().required(),
    GRAPHQL_API_ENDPOINT: Joi.string().required(),
    STAGE: Joi.string(),
    BUILD_NUMBER: Joi.string(),
    BUILD_COMMIT: Joi.string(),
    LEARN_URL: Joi.string(),
    BUGSNAG_API_KEY: Joi.string(),
    BUGSNAG_RELEASE_STAGE: Joi.string(),
    ACG_DOMAIN: Joi.string(),
    ACG_BUSINESS_DOMAIN: Joi.string(),
    SEGMENT_API_KEY: Joi.string(),
    SPLIT_IO_API_KEY: Joi.string(),
    PLURALSIGHT_LIBRARY_URL: Joi.string(),
    PS_LOGOUT_URL: Joi.string().required(),
  })
  .required();

export { schema };
