import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { colours, Flexbox } from '@a-cloud-guru/rainbow-ui';

const FullLoading = () => {
  return (
    <Flexbox alignItems="center">
      <SkeletonCircle margin="0 8px 0 0" />
      <SkeletonCircle margin="0 0 0 8px" />
    </Flexbox>
  );
};

const SkeletonCircle = styled.div`
  border-radius: 100%;
  background-color: ${rgba(colours.navy300, 0.25)};
  height: 54px;
  width: 54px;
  margin: ${(props) => props.margin};
`;

export { FullLoading };
