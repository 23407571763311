import React from 'react';
import { useAuth } from '@a-cloud-guru/react-auth';

import { LoadingScreen } from '../components/LoadingScreen';
import { redirectLogin } from './redirectLogin';

const withAuthorization = (ComposedAuthComponent) => {
  const Authorized = () => {
    const { loading, error } = useAuth();

    if (loading) {
      return <LoadingScreen />;
    }

    if (error) {
      redirectLogin();

      return <LoadingScreen />;
    }

    return <ComposedAuthComponent />;
  };

  return Authorized;
};

export { withAuthorization };
