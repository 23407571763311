import React from 'react';
import { LoadingScreen } from '../../components/LoadingScreen';
import { authService } from '../../services/auth';

const LoginCallback = () => {
  const processCallback = async () => {
    try {
      const { state } = await authService.loginCallback();
      const { redirect_to } = authService.decodeState(state);

      window.location.replace(redirect_to);
    } catch (err) {
      console.debug('LoginCallback failed', err);
      window.location.replace('/login');
    }
  };

  processCallback();

  return <LoadingScreen />;
};

export { LoginCallback };
