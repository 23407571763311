import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    color: ${colours.darkGrey900};
  }
`;

const Background = styled.div`
  background: ${colours.lightGrey500};
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export { Background, Container };
