const formatTime = (milliseconds) => {
  return new Date(milliseconds).toISOString().slice(11, 19);
};

const isUndefinedOrNull = (value) => {
  if (value === undefined || value === null) {
    return true;
  }
  return false;
};

export { formatTime, isUndefinedOrNull };
