import React from 'react';
import { colours, Button } from '@a-cloud-guru/rainbow-ui';
import { Skeleton } from 'antd';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { Card } from '../../components/Card/Card';
import { ReactComponent as TerminalIcon } from './terminal-icon.svg';
import { ReactComponent as DiagramIcon } from './diagram-icon.svg';

const Tools = ({ loading, diagramUrl }) => {
  return (
    <Card title="TOOLS">
      <Skeleton loading={loading} title={false} paragraph={{ rows: 1, width: 295 }}>
        <TerminalLink
          type="outlined"
          data-testid="tools-section"
          href="https://ssh.instantterminal.acloud.guru/"
          target="_blank"
        >
          <TerminalIcon />
          Instant Terminal
        </TerminalLink>
      </Skeleton>
      <Skeleton loading={loading} title={false} paragraph={{ rows: 1, width: 295 }}>
        {diagramUrl && (
          <DiagramLink href={diagramUrl} target="_blank">
            <DiagramIcon />
            Lab Diagram
          </DiagramLink>
        )}
      </Skeleton>
    </Card>
  );
};

const ButtonStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  &.ant-btn {
    padding-top: 6px !important; // Annoying antD override
  }
  :focus {
    border-radius: 4px;
    box-shadow: ${rgba(colours.blue500, 1)} 0px 0px 4px 2px;
  }
  span {
    margin-left: 8px;
  }
`;

const DiagramLink = styled(Button)`
  ${ButtonStyles}
`;

const TerminalLink = styled(Button)`
  ${ButtonStyles}
  border: 1px solid ${colours.blue700};
  color: ${colours.blue700};
`;

export { Tools };
