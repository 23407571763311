import React from 'react';
import 'antd/dist/antd.less';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider, defaultTheme } from '@a-cloud-guru/rainbow-ui';
import { AuthProvider } from '@a-cloud-guru/react-auth';
import { ApolloProvider } from '@apollo/client';
import styled from 'styled-components';
import { message } from 'antd';

import { getClient } from './lib/makeApolloClient';
import { Start } from './pages/Start/Start';
import { authService } from './services/auth';
import { withAuthorization } from './lib/withAuthorization';
import { Login } from './pages/Login/Login';
import { LoginCallback } from './pages/Login/Callback';
import { Result } from './pages/Result/Result';
import { NotFound } from './components/NotFound/NotFound';
import { Logout } from './pages/Logout/Logout';
import { ExamContainer } from './pages/Exam/ExamContainer';
import { LabContainer } from './pages/Lab/LabContainer';
import { Segment } from './components/segment/Segment';
import { SplitIO } from './components/SplitIO';

message.config({
  getContainer: () => {
    return document.getElementById('practice-exam-message-container');
  },
});

function App() {
  return (
    <AuthProvider client={authService}>
      <SplitIO>
        <Segment />
        <ApolloProvider client={getClient}>
          <ConfigProvider theme={defaultTheme}>
            <MessageContainer id="practice-exam-message-container"></MessageContainer>
            <Router>
              <Switch>
                <Route exact path="/login/callback" component={LoginCallback} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/:assessmentId" component={withAuthorization(Start)} />
                <Route exact path="/:assessmentId/exam/:attemptId" component={withAuthorization(ExamContainer)} />
                <Route exact path="/:assessmentId/lab/:attemptId" component={withAuthorization(LabContainer)} />
                <Route exact path="/:assessmentId/result/:attemptId" component={withAuthorization(Result)} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </ConfigProvider>
        </ApolloProvider>
      </SplitIO>
    </AuthProvider>
  );
}

const MessageContainer = styled.div`
  .ant-message-notice-content {
    padding: 0;
    box-shadow: none;

    .ant-message-custom-content {
      div {
        border-radius: 0;
      }
    }
  }
`;

export default App;
