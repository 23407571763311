import React, { useState } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { Skeleton, colours, breakpoints } from '@a-cloud-guru/rainbow-ui';
import { QuestionResults } from './QuestionResults';
import { DomainQuestionNavigation } from './DomainQuestionNavigation';
import { DetailSummary } from './DetailSummary';

const sortDomainQuestions = (questions) => {
  const incorrectQuestions = questions.filter((question) => get(question, 'grade.isCorrect') === false);
  const correctQuestions = questions.filter((question) => get(question, 'grade.isCorrect') === true);
  const unansweredQuestions = questions.filter((question) => question.submitted === false);

  return [...incorrectQuestions, ...unansweredQuestions, ...correctQuestions];
};

const QuestionDomain = ({ domain, questions, loading, index, assessmentId, ...props }) => {
  const domainQuestions = questions.filter((question) => question.poolId === domain.questionPoolId);
  const sortedDomainQuestions = sortDomainQuestions(domainQuestions);
  const [questionNumber, setQuestionNumber] = useState(sortedDomainQuestions[0].order);

  return (
    <DetailsContainer key={index} {...props}>
      <DetailSummary domain={domain} loading={loading} index={index}>
        <Skeleton loading={loading} title={false}>
          <DomainDescription data-testid={`domain-summary-description-${index}`}>
            {domain.domainDescription} Questions for this domain comprise{' '}
            <strong data-testid={`domain-summary-weight-${index}`}>{domain.weight}% of the total questions</strong> for
            this exam.
          </DomainDescription>
        </Skeleton>
        <DomainQuestionNavigation
          selectedQuestion={questionNumber}
          questions={sortedDomainQuestions}
          setQuestionNumber={setQuestionNumber}
          testIndex={index}
        />
      </DetailSummary>
      <QuestionResults question={questions[questionNumber]} domainIndex={index} assessmentId={assessmentId} />
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-top: 2px solid ${colours.lightGrey700};
  padding: 40px 0;

  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
  }
`;

const DomainDescription = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 22px;
  color: ${colours.darkGrey900};
`;

export { QuestionDomain, sortDomainQuestions };
