import React from 'react';
import styled from 'styled-components';
import { colours, breakpoints, Skeleton } from '@a-cloud-guru/rainbow-ui';
import { Card } from '../../components/Card/Card';
import { SectionHeading } from '../../components/SectionHeading';
import { DomainScoreDonut } from './DomainScoreDonut';

const DetailSummary = ({ domain, loading, index, children }) => {
  return (
    <DetailSummaryCard>
      <CardHeader>
        <Skeleton loading={loading} avatar={true} size="large" shape="cicrle" title={false} paragraph={false}>
          <DomainScoreDonut domainScore={domain.score} index={index} />
        </Skeleton>
        <Title>
          <Skeleton loading={loading} paragraph={false}>
            <SectionHeading>Domain</SectionHeading>
            <DomainName data-testid={`domain-summary-name-${index}`}>{domain.domainName}</DomainName>
          </Skeleton>
        </Title>
      </CardHeader>
      {children}
    </DetailSummaryCard>
  );
};

const DetailSummaryCard = styled(Card)`
  margin-right: 4%;
  width: 31%;
  height: fit-content;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-right: 0;
  }
`;

const CardHeader = styled.div`
  display: flex;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const DomainName = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 31px;
  color: ${colours.darkGrey900};
  margin: 6px 0 22px 0;
`;

export { DetailSummary };
