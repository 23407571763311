import urlJoin from 'url-join';
import { getConfig } from '../config/get-config';

const { ACG_PRACTICE_EXAM_DOMAIN } = getConfig();

const redirectLogin = () => {
  const loginUrl = urlJoin(ACG_PRACTICE_EXAM_DOMAIN, 'login');
  const currentUrl = window.location.href;
  window.location.replace(`${loginUrl}?redirect_to=${encodeURIComponent(currentUrl)}`);
};

export { redirectLogin };
