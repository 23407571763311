import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colours, Button } from '@a-cloud-guru/rainbow-ui';
import { Modal } from '../../components/Modal/Modal';
import { Card } from '../../components/Card/Card';

const SubmitLab = ({ onSubmit, loading, disabled, hasNextLab }) => {
  const [visible, setVisible] = useState(false);

  const handleSubmit = () => {
    onSubmit();
    setVisible(false);
  };

  return (
    <Card title="SUBMIT LAB">
      <SubmitLabButton type="primary" onClick={() => setVisible(true)} loading={loading} disabled={disabled}>
        Submit Lab
      </SubmitLabButton>
      <Modal
        wrapClassName="lab-page-submit-modal"
        title={hasNextLab ? 'Ready to submit lab?' : 'Ready to submit exam?'}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <FocusableButton type="outline" key="continue" onClick={() => setVisible(false)} tabIndex="0">
            Continue lab
          </FocusableButton>,
          <FocusableButton type="primary" key="submit" onClick={handleSubmit} tabIndex="0">
            {hasNextLab ? 'Move to next lab' : 'Submit exam'}
          </FocusableButton>,
        ]}
      >
        <p>
          {hasNextLab
            ? 'Are you ready to complete this lab and move on to the next one?'
            : 'Are you ready to submit the lab and complete the exam?'}
        </p>
      </Modal>
    </Card>
  );
};

const FocusableButton = styled(Button)`
  :focus {
    border-radius: 4px;
    box-shadow: ${rgba(colours.blue500, 1)} 0px 0px 4px 2px;
  }
`;

const SubmitLabButton = styled(Button)`
  width: 100%;
  margin-bottom: 8px;
  &.ant-btn {
    padding-top: 6px !important; // Annoying antD override
  }
  :focus {
    border-radius: 4px;
    box-shadow: ${rgba(colours.blue500, 1)} 0px 0px 4px 2px;
  }
  span {
    margin-left: 8px;
  }
`;

export { SubmitLab };
