import isUndefined from 'lodash/isUndefined';
import { useSplitTreatment } from 'splitio-react';
import { isCypress } from '../lib/isCypress';

const useSplit = (splitName) => {
  if (isCypress()) {
    return true;
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const treatment = useSplitTreatment(splitName);

  if (isUndefined(treatment)) {
    return;
  }

  return treatment === 'on';
};

export { useSplit };
