import React from 'react';
import { format } from 'date-fns';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { ContinueLink } from './ContinueLink';
import { PassedLink } from './PassedLink';
import { FailedLink } from './FailedLink';
import { IncompleteLabel } from './IncompleteLabel';

const AttemptResult = ({ assessmentId, attemptId, status, isInProgress, disabled, score }) => {
  if (isInProgress) {
    const continueUrl = status === 'lab' ? `/${assessmentId}/lab/${attemptId}` : `/${assessmentId}/exam/${attemptId}`;

    return <ContinueLink url={continueUrl} />;
  }

  const resultUrl = `/${assessmentId}/result/${attemptId}`;
  if (status === 'passed') {
    return <PassedLink url={resultUrl} score={score} disabled={disabled} />;
  }

  if (status === 'failed') {
    return <FailedLink url={resultUrl} score={score} disabled={disabled} />;
  }

  return <IncompleteLabel />;
};

const AttemptItem = ({
  assessmentId,
  attemptId,
  status,
  score,
  isMigrated,
  createdDate,
  endDate,
  index,
  hasPracticeExamAccess,
  activeLabInstanceUuid,
  labs = [],
}) => {
  const attemptTime = format(new Date(createdDate), 'MMM do, yyyy hh:mma');

  const isNotTimeout = new Date().toISOString() <= endDate;
  const isQuestionInProgress = isNotTimeout && status === 'incomplete';
  const isLabInProgress =
    isNotTimeout && status === 'lab' && labs.some((lab) => lab.instanceUuid === activeLabInstanceUuid);
  const isInProgress = isQuestionInProgress || isLabInProgress;

  return (
    <AttemptContainer data-testid={`start-page-attempt-history-${index}`}>
      <Paragraph disabled={isMigrated}>{attemptTime}</Paragraph>
      <AttemptResult
        assessmentId={assessmentId}
        attemptId={attemptId}
        status={status}
        isInProgress={isInProgress}
        disabled={isMigrated | !hasPracticeExamAccess}
        score={score}
      />
    </AttemptContainer>
  );
};

const AttemptContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  align-items: center;
`;

const Paragraph = styled.p`
  color: ${({ disabled }) => (disabled ? colours.darkGrey50 : colours.darkGrey900)};
  margin: 0px;
  width: 70%;
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
`;

export { AttemptItem };
