import React, { useContext } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button } from '@a-cloud-guru/rainbow-ui';
import { get } from 'lodash';
import { errorMessage } from '../../components/Message/Message';
import { SelectedAnswersContext } from './context/SelectedAnswersContext';
import { useHistory, useParams } from 'react-router-dom';
import { UPDATE_QUESTION_INDEX } from '../../lib/sharedGraphql';

const SUBMIT_ANSWER = gql`
  mutation Assessments_submitChoices($submitChoicesInput: Assessments_SubmitChoicesInput!) {
    Assessments_submitChoices(input: $submitChoicesInput) {
      ... on Assessments_QuestionResults {
        id
      }
      ... on Assessments_AttemptAlreadyGraded {
        message
      }
    }
  }
`;

const SubmitAnswer = ({ questionIndex, attempt }) => {
  const questions = attempt.questions;
  const { selectedAnswers } = useContext(SelectedAnswersContext);
  const showErrorMessage = (message) =>
    errorMessage(message || 'Oops, something went wrong! Please try again.', 'exam-page');

  const { assessmentId } = useParams();
  const history = useHistory();
  const [submitAnswer, { loading: submitAnswerLoading }] = useMutation(SUBMIT_ANSWER, {
    onError() {
      showErrorMessage();
    },
  });
  const [updateQuestionIndex] = useMutation(UPDATE_QUESTION_INDEX.MUTATION, {
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      if (!get(data, 'Assessments_v2_updateQuestionIndex')) {
        showErrorMessage(
          'You do not have permission to perform this action. Check your subscription and try again later.'
        );
        return;
      }
    },
  });
  const currentQuestion = questions[questionIndex];
  const answerSelected = selectedAnswers.length === currentQuestion.possibleCorrect;

  const goToNextQuestion = () => {
    const newQuestionIndex = (questionIndex + 1) % questions.length;
    updateQuestionIndex(
      UPDATE_QUESTION_INDEX.options({
        questionNumber: newQuestionIndex,
        attempt,
        assessmentId,
      })
    );
    return newQuestionIndex;
  };

  const handleSubmitAnswer = () => {
    submitAnswer({
      variables: {
        submitChoicesInput: {
          attemptId: attempt.id,
          questionId: currentQuestion.id,
          selectedChoiceIds: selectedAnswers,
        },
      },
      update(cache, { data: { Assessments_submitChoices } }) {
        if (!Assessments_submitChoices) {
          return;
        }
        if (Assessments_submitChoices.message) {
          showErrorMessage('This exam has been completed');
          history.push(`/${assessmentId}/result/${attempt.id}`);
          return;
        }
        cache.modify({
          id: cache.identify(currentQuestion),
          fields: {
            submitted() {
              return true;
            },
            selectedChoiceIds() {
              return selectedAnswers;
            },
          },
        });
        const newQuestionIndex = goToNextQuestion();
        cache.modify({
          id: cache.identify(attempt),
          fields: {
            currentQuestionIndex() {
              return newQuestionIndex;
            },
          },
        });
      },
    });
  };

  return (
    <Button
      data-testid="exam-page-submit-answer-button"
      type="primary"
      disabled={!answerSelected || submitAnswerLoading}
      loading={submitAnswerLoading}
      onClick={handleSubmitAnswer}
    >
      Answer
    </Button>
  );
};

export { SubmitAnswer };
