import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { colours, Radio } from '@a-cloud-guru/rainbow-ui';
import { Markdown } from '../../components/Markdown';
import { SelectedAnswersContext } from './context/SelectedAnswersContext';

const SingleSelect = ({ choices = [], submitted, selectedChoiceIds }) => {
  const { selectedAnswers, setSelectedAnswers } = useContext(SelectedAnswersContext);
  const handleSelect = (e) => {
    setSelectedAnswers([e.target.value]);
  };

  useEffect(() => {
    setSelectedAnswers(selectedChoiceIds || []);
  }, [selectedChoiceIds, setSelectedAnswers]);

  const choiceList = choices?.map((choice) => {
    return (
      <StyledRadio
        key={choice.id}
        disabled={submitted}
        value={choice.id}
        data-testid={`choice-radio-${choice.id}`}
        onClick={handleSelect}
      >
        <Text ignoreLink={true} text={choice.text} />
      </StyledRadio>
    );
  });

  return (
    <StyledRadioGroup value={selectedAnswers[0]} data-testid="choices-container">
      {choiceList}
    </StyledRadioGroup>
  );
};

const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper {
    background-color: ${colours.lightGrey200};
    width: 100%;
    display: flex;
    font-size: 16px;
    word-break: break-word;
    padding: 13px 16px;
    margin-bottom: 8px;
    white-space: normal;
  }
  &.ant-radio-wrapper-checked {
    background-color: ${colours.lightGrey400};
  }
  &.ant-radio-wrapper-disabled {
    pointer-events: none;
    background-color: ${colours.lightGrey100};

    p {
      color: ${colours.darkGrey300};
    }

    .ant-radio-inner {
      background-color: ${colours.lightGrey100};
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: ${colours.blue700};
    }
  }
  .ant-radio-checked.ant-radio-disabled {
    .ant-radio-inner {
      background-color: ${colours.lightGrey600};
    }
  }
  .ant-radio-disabled {
    .ant-radio-inner {
      border-color: ${colours.lightGrey600} !important; /* needed because antd use !important in their internal styles */
    }
  }
  .ant-radio-checked::after {
    border: 0px;
  }
  .ant-radio-inner::after {
    background-color: ${colours.white};
    width: 6px;
    height: 6px;
    top: 4px;
    left: 4px;
  }
  .ant-radio {
    padding-top: 4px;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    outline: none;
    box-shadow: none;
  }

  span:last-child {
    overflow: hidden;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  margin-bottom: 24px;
`;

const Text = styled(Markdown)`
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  color: ${colours.darkGrey900};
`;

export { SingleSelect };
