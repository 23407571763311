import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { colours, Card, Button, breakpoints } from '@a-cloud-guru/rainbow-ui';
import { get } from 'lodash';
import { LoadingScreen } from '../../components/LoadingScreen';
import { Markdown } from '../../components/Markdown';
import { Choices } from './Choices';
import { Navigation } from './Navigation';
import { SubmitAnswer } from './SubmitAnswer';
import { Header } from '../../components/Header/Header';
import { SectionHeading } from '../../components/SectionHeading';
import { FlagButton } from './FlagButton';
import { ReactComponent as EditIcon } from './images/edit-answer.svg';
import { ReactComponent as LockIcon } from './images/lock.svg';
import { ReactComponent as PinkFlag } from './images/pink-flag.svg';
import { errorMessage } from '../../components/Message/Message';
import { Timer } from '../../components/Header/Timer';
import { Container, Background } from '../../components/Layout';
import { CURRENT_ATTEMPT } from './../../lib/sharedGraphql';
import { Overlay } from '../../components/Overlay/Overlay';
import { SubmitContext } from '../../contexts/submit/SubmitContext';
import { Bugsnag } from '../../lib/bugsnag';
import { isUndefinedOrNull } from '../../lib/util';

const FLAG_QUESTION = gql`
  mutation updateQuestionFlag(
    $input: Assessments_QuestionFlagInput!
    $contentType: Assessments_AccessPermissions_ContentType!
  ) {
    Assessments_v2_flagQuestion(input: $input, contentType: $contentType) {
      ... on Assessments_AttemptQuestion {
        id
        questionText
        type
        possibleCorrect
        submitted
        flagged
        choices {
          id
          text
        }
      }
      ... on Assessments_AttemptAlreadyGraded {
        message
      }
    }
  }
`;

const Exam = () => {
  const { attemptId, assessmentId } = useParams();
  const history = useHistory();

  const showErrorMessage = (message) =>
    errorMessage(message || 'Oops, something went wrong! Please try again.', 'exam-page');
  const { data, loading } = useQuery(CURRENT_ATTEMPT.QUERY, {
    variables: {
      input: {
        id: attemptId,
        assessmentId,
      },
      contentType: 'PRACTICE_EXAM',
    },
    onCompleted(data) {
      if (!get(data, 'Assessments_v2_attempt')) {
        showErrorMessage(
          'You do not have permission to perform this action. Check your subscription and try again later.'
        );
        return;
      }

      const assessmentAttempt = get(data, 'Assessments_v2_attempt', {});
      if (assessmentAttempt.status === 'lab') {
        return history.push(`/${assessmentId}/lab/${attemptId}`);
      }

      if (assessmentAttempt.status === 'passed' || assessmentAttempt.status === 'failed') {
        showErrorMessage('This exam has been completed');
        return history.push(`/${assessmentId}/result/${attemptId}`);
      }
    },
  });
  const currentAttempt = get(data, 'Assessments_v2_attempt', {});
  const questionIndex = get(currentAttempt, 'currentQuestionIndex', 0);
  const currentQuestion = get(currentAttempt, `questions[${questionIndex}]`, {});
  const isFlagged = get(currentQuestion, `flagged`, false);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  const { submitExam, loading: gradingAttempt } = useContext(SubmitContext);

  if (isUndefinedOrNull(questionIndex) || isUndefinedOrNull(currentAttempt)) {
    Bugsnag.notify('questionIndex or currentAttempt is undefined', (event) =>
      event.addMetadata('Meta', {
        questionIndex,
        currentAttempt,
      })
    );
  }

  useEffect(() => {
    setAnswerSubmitted(currentQuestion.submitted);
  }, [currentQuestion.submitted, currentQuestion.selectedChoiceIds, questionIndex]);

  const [flagQuestion] = useMutation(FLAG_QUESTION, {
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      if (!get(data, 'Assessments_v2_flagQuestion')) {
        showErrorMessage(
          'You do not have permission to perform this action. Check your subscription and try again later.'
        );
        return;
      }

      const message = get(data, 'Assessments_v2_flagQuestion.message');
      if (message) {
        showErrorMessage('This exam has been completed');
      }
    },
  });

  if (get(data, 'Assessments_v2_attempt') === null) {
    history.push(`/${assessmentId}`);
    return <></>;
  }

  if (!currentAttempt.questions || loading) {
    return <LoadingScreen />;
  }

  const toggleQuestionFlag = () => {
    const flagStatus = !isFlagged;
    flagQuestion({
      variables: {
        input: {
          attemptId,
          questionId: currentQuestion.id,
          flagStatus,
          assessmentId,
        },
        contentType: 'PRACTICE_EXAM',
      },
      optimisticResponse: {
        __typename: 'Mutation',
        Assessments_v2_flagQuestion: {
          ...currentQuestion,
          flagged: flagStatus,
        },
      },
    });
  };

  const resetAnswer = () => {
    setAnswerSubmitted(false);
  };

  const getQuestionHint = () => (
    <PossibleCorrect data-testid="exam-page-possible-correct">
      (Choose {currentQuestion.possibleCorrect})
    </PossibleCorrect>
  );

  const getButton = () => {
    if (answerSubmitted) {
      return (
        <ChangeAnswerButton onClick={resetAnswer} data-testid="exam-page-change-answer-button" type="primary">
          <ChangeAnswerIconContainer>
            <EditIcon />
          </ChangeAnswerIconContainer>
          Change Answer
        </ChangeAnswerButton>
      );
    }
    return <SubmitAnswer questionIndex={questionIndex} attempt={currentAttempt} />;
  };

  return (
    <>
      <Helmet>
        <title>{`Practice Exam – ${get(currentAttempt, 'title', '')} – A Cloud Guru`}</title>
      </Helmet>
      <Background>
        <Overlay show={gradingAttempt}>
          <Header examName={currentAttempt.title} coverImageUrl={currentAttempt.coverImageUrl}>
            <Timer endTime={currentAttempt.assessmentEndDate} onExpire={submitExam} />
          </Header>
          <ExamContainer>
            <SubContainer>
              <ExamCard>
                <QuestionHeading>
                  <SectionHeading data-testid="exam-page-question-index">Question {questionIndex + 1}</SectionHeading>
                  <FlagSection>
                    <div>{answerSubmitted && <LockIcon data-testid="lock-icon" />}</div>
                    <div>{isFlagged && <PinkFlag data-testid="exam-page-header-flag-icon" />}</div>
                  </FlagSection>
                </QuestionHeading>
                <Question data-testid="exam-page-question-text">
                  <Markdown text={currentQuestion.questionText} />
                  {currentQuestion.type === 'multi-answer' && getQuestionHint()}
                </Question>
                <Choices submitted={answerSubmitted} question={currentQuestion} questionIndex={questionIndex} />
                <ActionFooter>
                  {getButton()}
                  <FlagButton flagged={isFlagged} toggleQuestionFlag={toggleQuestionFlag} />
                </ActionFooter>
              </ExamCard>
            </SubContainer>
            <Navigation attempt={currentAttempt} questionIndex={questionIndex} assessmentId={assessmentId} />
          </ExamContainer>
        </Overlay>
      </Background>
    </>
  );
};

const ExamContainer = styled(Container)`
  width: 100%;
  align-items: center;
  margin: 48px 0px 211px 0px;
  @media (min-width: ${breakpoints.xl}) {
    align-items: flex-start;
    flex-direction: row;
  }
`;

const FlagSection = styled.div`
  display: flex;
  align-items: start;

  div {
    margin-left: 14px;
  }
`;

const ActionFooter = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 8px;
  }
`;

const SubContainer = styled.main`
  width: 728px;
  @media (min-width: ${breakpoints.xl}) {
    margin-right: 51px;
  }
`;

const ExamCard = styled(Card)`
  box-shadow: 0px 2px 14px rgba(173, 186, 208, 0.303431);
  border-radius: 5px;
`;

const QuestionHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  line-height: 16px;
`;

const Question = styled.div`
  margin-bottom: 24px;
  font-weight: 500;
  line-height: 24px;
`;

const PossibleCorrect = styled.span`
  color: ${colours.darkGrey50};
  margin-left: 4px;
`;

const ChangeAnswerButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const ChangeAnswerIconContainer = styled.span`
  width: 16px;
  height: 18px;
  margin-right: 6px;
`;

export { Exam };
