const testConfig = {
  AUTH0_CLIENT_ID: 'fljNY6H0JVMd41gR0o7ATdSiETUD78rh',
  AUTH0_DOMAIN: 'staging-acloudguru.auth0.com',
  AUTH0_CUSTOM_DOMAIN: 'staging-auth.acloud.guru',
  AUTH0_API_AUDIENCE: 'https://staging-acloudguru.auth0.com/api/v2/',
  ACG_PRACTICE_EXAM_DOMAIN: 'https://staging-practice-exam.acloud.guru',
  GRAPHQL_API_ENDPOINT: 'https://test-api.acloud.guru/bff/graphql',
  LEARN_URL: 'https://staging-learn.acloud.guru',
  BUGSNAG_RELEASE_STAGE: 'staging',
  ACG_DOMAIN: 'https://test-auto.acloud.guru',
  ACG_BUSINESS_DOMAIN: 'https://staging-business.acloud.guru',
  SEGMENT_API_KEY: 'S5c7xIE034xM7y20CZsHxgnJowSijYdM',
  PS_LOGOUT_URL: 'http://app-stage.pluralsight.com/id/signout',
};

export { testConfig };
