import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { colours, Flexbox } from '@a-cloud-guru/rainbow-ui';

const CompactLoading = () => {
  return (
    <Flexbox alignItems="center">
      <SkeletonCircle />
      <SkeletonCircle />
      <SkeletonText />
    </Flexbox>
  );
};

const SkeletonCircle = styled.div`
  border-radius: 100%;
  background-color: ${rgba(colours.navy300, 0.25)};
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

const SkeletonText = styled(SkeletonCircle)`
  border-radius: 8px;
  width: 140px;
`;

export { CompactLoading };
