import React from 'react';
import styled from 'styled-components';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import { Divider } from 'antd';
import { SectionHeading } from '../../components/SectionHeading';
import { colours, Row, Col } from '@a-cloud-guru/rainbow-ui';
import { ReactComponent as QuestionIcon } from './images/question.svg';
import { ReactComponent as ClockIcon } from './images/clock.svg';
import { ReactComponent as CalendarIcon } from './images/calendar.svg';

const pluralize = (str, number) => (number === 1 ? str : `${str}s`);

const Tip = ({ icon, title, message, testid }) => (
  <TipCol data-testid="result-page-tip-col" span={8}>
    <TipIcon data-testid={`result-page-tip-${testid}-icon`}>{icon}</TipIcon>
    <TipBody>
      <TipTitle data-testid={`result-page-tip-${testid}-title`}>{title}</TipTitle>
      <TipDescription data-testid={`result-page-tip-${testid}-description`}>{message}</TipDescription>
    </TipBody>
  </TipCol>
);

const calculateTimeRemaining = ({ endDate, gradedDate }) => {
  const minutesRemaining = differenceInMinutes(new Date(endDate), new Date(gradedDate));
  const secondsRemaining = differenceInSeconds(new Date(endDate), new Date(gradedDate));

  let timeRemainingMessage = `${minutesRemaining} ${pluralize('minute', minutesRemaining)}`;
  if (minutesRemaining === 0 && secondsRemaining > 0) {
    timeRemainingMessage = `${secondsRemaining} ${pluralize('second', secondsRemaining)}`;
  }

  return {
    hasTimeRemaining: minutesRemaining > 0 || secondsRemaining > 0,
    timeRemainingMessage,
  };
};

const QuickTips = ({ questions, passed, gradedDate, endDate }) => {
  const timeRemaining = calculateTimeRemaining({
    gradedDate,
    endDate,
  });

  questions = questions || [];
  const insights = [
    {
      icon: <QuestionIcon />,
      testid: 'question',
      id: 'unansweredQuestions',
      title: "You didn't answer everything",
      message:
        'You left some questions unanswered. You won’t lose marks for incorrect guesses, so try to choose something anyway.',
      show: questions.filter((question) => !question.submitted).length > 0,
      order: 1,
    },
    {
      icon: <QuestionIcon />,
      testid: 'flag',
      id: 'noFlag',
      title: 'Remember to flag things',
      message: "If you're not sure about a question, flag it and move on. Come back to it when you're ready!",
      show: questions.filter((question) => !question.flagged).length === questions.length,
      order: 2,
    },
    {
      icon: <ClockIcon />,
      testid: 'time',
      id: 'outOfTime',
      title: 'You ran out of time',
      message:
        "Keep an eye on the clock! You want to be sure you've answered everything before you have to submit your exam.",
      show: !timeRemaining.hasTimeRemaining,
      order: 3,
    },
    {
      icon: <ClockIcon />,
      testid: 'early',
      id: 'finishedEarly',
      title: 'You finished early',
      message: `You had an extra ${timeRemaining.timeRemainingMessage}. We recommend using all of the available time to double check your answers, especially those you are uncertain of.`,
      show: timeRemaining.hasTimeRemaining,
      order: 4,
    },
    {
      icon: <CalendarIcon />,
      testid: 'again',
      id: 'again',
      title: 'Keep studying!',
      message: "Your exam result probably won't result in a pass. We recommend you keep studying and try again.",
      show: !passed,
      order: 5,
    },
  ];

  return (
    <div>
      <DivideSection>
        <QuickTip data-testid="result-page-quick-tips-heading">Quick Tips</QuickTip>
        <TipDivider />
      </DivideSection>
      <TipRow>
        {insights
          .filter((insight) => insight.show)
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .slice(0, 3)
          .map((insight) => (
            <Tip
              key={insight.id}
              icon={insight.icon}
              testid={insight.testid}
              title={insight.title}
              message={insight.message}
            />
          ))}
      </TipRow>
    </div>
  );
};

const TipBody = styled.div`
  text-align: left;
`;

const TipRow = styled(Row)`
  margin: 13px 28px 24px 28px;
`;

const TipCol = styled(Col)`
  display: flex;
  align-items: start;
`;

const TipDivider = styled(Divider)`
  flex-grow: 1;
  width: 0;
  min-width: 0;
  margin-right: 28px;
`;

const QuickTip = styled(SectionHeading)`
  min-width: 138px;
`;

const DivideSection = styled.div`
  display: flex;
  align-items: center;
`;

const TipIcon = styled.p`
  margin-right: 10px;
`;

const TipDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colours.darkGray700};
`;

const TipTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  color: ${colours.darkGrey900};
`;

export { QuickTips };
