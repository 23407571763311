import React from 'react';
import styled, { css } from 'styled-components';
import { colours, Button } from '@a-cloud-guru/rainbow-ui';

import { ReactComponent as LabIcon } from './images/lab-icon.svg';

const ActiveLabBanner = ({ onCancelClick = () => {}, loading }) => (
  <Banner>
    <Message>
      <LabIcon />
      Your hands-on lab is still running.
    </Message>
    <div>
      <StyledButton data-testid="cancel-lab-btn" onClick={onCancelClick} type="link" loading={loading}>
        Cancel Lab
      </StyledButton>
    </div>
  </Banner>
);

const Banner = styled.div`
  background-color: ${colours.yellow};
  color: ${colours.navy900};
  font-weight: 500;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Message = styled.div`
  display: flex;
  place-items: center;

  svg {
    width: 12px;
    margin-right: 8px;

    g {
      fill: ${colours.navy900};
    }
  }
`;

const linkButtonStyles = css`
  color: ${colours.navy900};
  border-color: transparent;
  background-color: transparent;
`;

const StyledButton = styled(Button)`
  ${linkButtonStyles}

  > span {
    text-decoration: underline;
  }

  &&&&:hover {
    ${linkButtonStyles}

    > span {
      text-decoration: none;
    }
  }
`;

export { ActiveLabBanner };
