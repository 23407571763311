import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Checkbox } from 'antd';
import { Markdown } from '../../components/Markdown';
import { SelectedAnswersContext } from './context/SelectedAnswersContext';

const MultiSelect = ({ choices = [], submitted, possibleCorrect, selectedChoiceIds, questionIndex }) => {
  const { selectedAnswers, setSelectedAnswers } = useContext(SelectedAnswersContext);
  const [maxSelected, setMaxSelected] = useState(false);
  const handleSelect = (selectedIds) => {
    setMaxSelected(selectedIds.length === possibleCorrect);
    setSelectedAnswers(selectedIds);
  };

  useEffect(() => {
    const currentChoices = selectedChoiceIds || [];
    setSelectedAnswers(currentChoices);
    setMaxSelected(currentChoices.length === possibleCorrect);
  }, [questionIndex, selectedChoiceIds, setSelectedAnswers, possibleCorrect]);

  const choiceList = choices?.map((choice) => {
    const choiceSelected = selectedAnswers.includes(choice.id);
    return (
      <StyledCheckbox
        key={choice.id}
        disabled={submitted || (maxSelected && !choiceSelected)}
        value={choice.id}
        data-testid={`choice-checkbox-${choice.id}`}
      >
        <Text ignoreLink={true} text={choice.text} />
      </StyledCheckbox>
    );
  });

  return (
    <ChoiceListContainer value={selectedAnswers} data-testid="choices-container" onChange={handleSelect}>
      {choiceList}
    </ChoiceListContainer>
  );
};

const ChoiceListContainer = styled(Checkbox.Group)`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    background-color: ${colours.lightGrey200};
    border: 1px solid ${colours.lightGrey200};
    width: 100%;
    display: flex;
    align-items: start;
    font-size: 16px;
    word-break: break-word;
    padding: 13px 16px;
    margin: 0 0 8px 0;
    white-space: normal;
    border-radius: 2px;

    .ant-checkbox {
      margin-top: 2px;
    }

    &:active {
      border: 1px solid ${colours.blue700};
    }
    &:focus-within {
      border: 1px solid ${colours.blue700};
    }
  }

  &.ant-checkbox-wrapper-checked {
    background-color: ${colours.lightGrey400};
  }

  &.ant-checkbox-wrapper-disabled {
    pointer-events: none;
    background-color: ${colours.lightGrey100};

    p {
      color: ${colours.darkGrey300};
    }

    .ant-checkbox-inner {
      background-color: ${colours.lightGrey100};
    }
  }
  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      border-color: ${colours.lightGrey600} !important; /* needed because antd use !important in their internal styles */
    }
  }

  .ant-checkbox-disabled.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${colours.lightGrey600};
    }
    .ant-checkbox-inner::after {
      border-color: ${colours.white};
    }
  }

  span:last-child {
    overflow: hidden;
  }
`;

const Text = styled(Markdown)`
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  color: ${colours.darkGrey900};
`;

export { MultiSelect };
