import React, { useState } from 'react';
import styled from 'styled-components';
import { colours, breakpoints, Skeleton } from '@a-cloud-guru/rainbow-ui';
import { DetailSummary } from './DetailSummary';
import { DomainLabNavigation } from './DomainLabNavigation';
import { LabResults } from './LabResults';

const labPassed = (lab) => {
  const minPassingObjectiveCount = lab.minPassingObjectiveCount ?? null;
  if (!lab.instanceUuid) {
    return null;
  }
  if (minPassingObjectiveCount === null) {
    return false;
  }
  const objectives = lab.tasks ?? [];
  const passedObjectiveCount = objectives.filter((objective) => objective.isPassed === true).length;
  return passedObjectiveCount >= minPassingObjectiveCount;
};

const transformAndSortLabs = (labs) => {
  const transformedLabs = labs.map((lab, index) => ({
    order: index,
    isPassed: labPassed(lab),
    isSubmitted: !!lab.instanceUuid,
    ...lab,
  }));

  const failedLabs = transformedLabs.filter((lab) => lab.isPassed === false);
  const unattemptedLabs = transformedLabs.filter((lab) => lab.isSubmitted === false);
  const passedLabs = transformedLabs.filter((lab) => lab.isPassed === true);

  return [...failedLabs, ...unattemptedLabs, ...passedLabs];
};

const LabDomain = ({ domain, loading, labs, assessmentId, ...props }) => {
  const [selectedLab, setSelectedLab] = useState(0);
  const sortedLabs = transformAndSortLabs(labs);

  return (
    <DetailsContainer {...props}>
      <DetailSummary domain={domain} loading={loading}>
        <Skeleton loading={loading} title={false}>
          <DomainDescription>
            Labs comprise <strong>{domain.weight}% of the total </strong> for this exam.
          </DomainDescription>
        </Skeleton>
        <DomainLabNavigation labs={sortedLabs} selectedLab={selectedLab} setSelectedLab={setSelectedLab} />
      </DetailSummary>
      <LabResults
        description={sortedLabs[selectedLab]?.description}
        objectives={sortedLabs[selectedLab]?.tasks}
        labId={sortedLabs[selectedLab]?.id}
        assessmentId={assessmentId}
      />
    </DetailsContainer>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  border-top: 2px solid ${colours.lightGrey700};
  padding: 40px 0;

  @media (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
  }
`;

const DomainDescription = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 22px;
  color: ${colours.darkGrey900};
`;

export { LabDomain, transformAndSortLabs, labPassed };
