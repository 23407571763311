import { getConfig } from '../../config/get-config';
import React from 'react';
import { authService } from '../../services/auth';
import { get } from 'lodash';
import urlJoin from 'url-join';

const { LEARN_URL, PS_LOGOUT_URL } = getConfig();

class Logout extends React.Component {
  async componentDidMount() {
    await this.props.client.resetStore();
    const returnTo = get(this.props, 'router.query.returnTo', undefined);
    if (!returnTo) {
      window.location.href = `${PS_LOGOUT_URL}?redirectTo=${encodeURI(`${LEARN_URL}/login`)}`;
    } else {
      await authService.logout({
        returnTo: get(this.props, 'router.query.returnTo') || urlJoin(LEARN_URL, 'login'),
      });
    }
  }

  render() {
    return null;
  }
}

export { Logout };
