import React from 'react';
import styled from 'styled-components';
import { marked } from 'marked';
import { colours } from '@a-cloud-guru/rainbow-ui';

const Markdown = ({ ignoreLink, fontSize, paragraphColor, text, ...props }) => {
  const renderer = new marked.Renderer();
  const renderListItem = renderer.listitem.bind(renderer);
  const renderList = renderer.list.bind(renderer);
  const renderLink = renderer.link.bind(renderer);
  renderer.list = function (text, ordered, number) {
    let html = renderList(text, ordered, number);
    if (text.includes("<input class='task-list-item-checkbox'")) {
      html = html.replace('<ul>', '<ul class="task-list">');
    }
    return html;
  };
  renderer.listitem = function (text, task) {
    let html = renderListItem(text, task);
    if (task) {
      html = html.replace('<input ', "<input class='task-list-item-checkbox' ");
      html = html.replace('<li>', "<li class='task-list-item'>");
      html = html.replace('<p>', '');
      html = html.replace('</p>', '');
    }
    return html;
  };

  renderer.link = (href, title, text) => {
    let html = renderLink(href, title, text);
    if (ignoreLink) {
      return html.replace(/href=".*"/gi, '');
    }
    return `<a target="_blank" href="${href}" title="${title}">${text}</a>`;
  };

  return (
    <MarkdownWrapper
      fontSize={fontSize || '16px'}
      paragraphColor={paragraphColor || colours.darkGrey900}
      dangerouslySetInnerHTML={{ __html: marked(text || '', { renderer }) }}
      {...props}
    />
  );
};

const MarkdownWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colours.darkGrey900};
    font-weight: bold;
  }

  blockquote {
    border-left: 3px solid ${colours.darkGrey600};
    padding: 0 10px;
  }

  code {
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    font-size: ${({ fontSize }) => fontSize};
  }

  a {
    word-break: break-word;
  }

  p {
    color: ${({ paragraphColor }) => paragraphColor} !important;
    font-size: ${({ fontSize }) => fontSize};
    margin: 0 0 10px;
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    code {
      color: ${colours.blue700};
      background: ${colours.blue200};
      padding: 3px 6px 2px;
      border-radius: 3px;
    }
  }

  ul,
  ol {
    font-size: ${({ fontSize }) => fontSize};
  }

  ul.task-list {
    padding-left: 0;
    li.task-list-item {
      list-style-type: none;
    }
  }

  pre {
    background: ${colours.blue200};
    border-radius: 3px;
    padding: 2px 6px 4px;
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
    code {
      color: ${colours.blue700};
    }
  }

  img {
    max-width: 100%;
  }
`;

export { Markdown };
