import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { getConfig } from '../../config/get-config';
import { BackLink } from '../../components/BackLink';
import { logoPaths } from '../../lib/logoPaths';

const { LEARN_URL } = getConfig();

const LogoHeader = () => {
  return (
    <Container data-testid="logo-header">
      <LogoLink href={LEARN_URL}>
        <img data-testid="logo-header-image" src={logoPaths.onLight} alt="A Cloud Guru logo" />
      </LogoLink>
      <BackLink
        data-testid="logo-header-dashboard-button"
        url={`${LEARN_URL}/dashboard`}
        linkText={'Back to dashboard'}
      />
    </Container>
  );
};

const Container = styled.header`
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 84px;
`;

const LogoLink = styled.a`
  display: flex;
  padding: 10px;
  position: relative;
  z-index: 0;
  left: -10px;

  &:focus {
    border-radius: 4px;
    box-shadow: ${colours.blue500} 0px 0px 4px 2px;
  }
`;

export { LogoHeader };
