import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SelectedAnswersProvider } from './context/SelectedAnswersContext';
import { SubmitProvider } from '../../contexts/submit/SubmitContext';
import { Exam } from './Exam';

const ExamContainer = (props) => {
  const { attemptId, assessmentId } = useParams();
  const history = useHistory();

  return (
    <SelectedAnswersProvider>
      <SubmitProvider attemptId={attemptId} assessmentId={assessmentId} history={history}>
        <Exam />
      </SubmitProvider>
    </SelectedAnswersProvider>
  );
};

export { ExamContainer };
