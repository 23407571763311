import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { authService } from '../../services/auth';
import { get } from 'lodash';
import { makeFeatureHasAccessLevel } from '@a-cloud-guru/packaging-feature-access';
import { colours, Button, Card, Skeleton } from '@a-cloud-guru/rainbow-ui';
import { getConfig } from '../../config/get-config';
import { Header } from './Header';
import { Domains } from './Domains';
import { AttemptHistory } from './AttemptHistory/AttemptHistory';
import { errorMessage, successMessage } from '../../components/Message/Message';
import { Markdown } from '../../components/Markdown';
import { ActiveLabBanner } from './ActiveLabBanner';
import { StartExamButton } from './StartExamButton/StartExamButton';
import { CURRENT_ATTEMPT } from './../../lib/sharedGraphql';
import { NotFound } from '../../components/NotFound/NotFound';
import { LogoHeader } from './LogoHeader';
import { ReactComponent as GraphIcon } from '../../components/CallOut/graph-icon.svg';
import { ReactComponent as DocumentIcon } from '../../components/CallOut/DocumentIcon.svg';
import { CallOut } from '../../components/CallOut/CallOut';
import { TextSummary } from '../../components/TextSummary/TextSummary';
import { identify, track } from '../../lib/segment';
import { useSplit } from '../../hooks/useSplit';
import { GET_USER_BANNED } from './graphql/getUserBanned';
import { UserBanned } from '../Exam/UserBannedMessage';

const { ACG_DOMAIN, ACG_BUSINESS_DOMAIN, PLURALSIGHT_LIBRARY_URL, LEARN_URL } = getConfig();

const ASSESSMENT = gql`
  query Assessments_assessment($id: String, $withLabs: Boolean) {
    Assessments_assessment(id: $id, withLabs: $withLabs) {
      id
      title
      description
      numberOfQuestions
      numberOfLabs
      totalQuestionsCount
      timeLimit
      coverImageUrl
      assessmentType
      questionPool {
        weight
        domainName
      }
      labs {
        weight
      }
      contentAccess {
        hasAccess
      }
    }
    Assessments_attemptHistory(id: $id) {
      id
      status
      grade {
        score
      }
      createdDate
      assessmentEndDate
      migrated
      labs {
        instanceUuid
      }
    }
    Packaging_userFeatureAccess {
      userFeatureAccess {
        features
      }
    }
    LabKeep_myLab {
      instance {
        uuid
      }
    }
  }
`;

const CREATE_ATTEMPT = gql`
  mutation Assessments_createAttempt($input: Assessments_CreateAttemptInput!) {
    Assessments_createAttempt(input: $input) {
      ... on Assessments_Attempt {
        id
        title
        status
        numberOfQuestions
        assessmentEndDate
        currentQuestionIndex
        coverImageUrl
        questions {
          id
          questionText
          type
          possibleCorrect
          submitted
          flagged
          selectedChoiceIds
          order
          choices {
            id
            text
          }
        }
      }
    }
  }
`;

const IS_USER_PS_B2B = gql`
  query PsUserPlanDetails($input: OrganisationPlanUser_UserDetailsInput!) {
    OrganisationPlanUser_psUserPlanDetailsById(input: $input) {
      pluralsightId
      planRoles {
        planId
        planRole
      }
    }
  }
`;

const CANCEL_LAB = gql`
  mutation LabKeep_shutdownMyLabInstance($shutdownType: String!) {
    LabKeep_shutdownMyLabInstance(shutdownType: $shutdownType) {
      labInstance {
        lab {
          id
        }
      }
    }
  }
`;

const GET_USERID = gql`
  query UserIdentity {
    userIdentity {
      id
      pluralsightId
      originatesFrom
    }
  }
`;

const GET_ROLES = gql`
  query Roles {
    roles {
      organisationAdmin
      organisationAdminOnly
      teamCoordinatorOnly
      admin
      editor
    }
  }
`;

const SEND_ACCESS_REQUEST_EMAIL = gql`
  mutation sendAccessRequestEmail {
    sendAccessRequestEmail
  }
`;

const QUESTION_COUNT_MULTIPLIER = 3;

const Start = () => {
  const [pluralsightRedirectUser, setPluralsightRedirectUser] = useState(false);
  const { assessmentId } = useParams();
  const [userBanned, setUserBanned] = useState(false);
  const showErrorMessage = () => errorMessage('Oops, something went wrong! Please try again.', 'start-page');
  const [isPluralsightOriginatingB2B, setIsPluralsightOriginatingB2B] = useState(null);

  const [showActiveLabBanner, setShowActiveLabBanner] = useState(false);

  const [cancelLab, { loading: cancelLabLoading }] = useMutation(CANCEL_LAB, {
    variables: {
      shutdownType: 'Cancelled',
    },
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      setShowActiveLabBanner(!!data?.LabKeep_myLab?.instance);
    },
  });

  useQuery(GET_USER_BANNED, {
    onCompleted: (data) => {
      setUserBanned(get(data, 'LabKeep_getAllPlaygroundLabs.userBanned'), false);
    },
  });

  const [sendAccessRequestEmail, { loading: sendAccessRequestLoading }] = useMutation(SEND_ACCESS_REQUEST_EMAIL, {
    onCompleted: (emailSent) => {
      emailSent
        ? successMessage('Email sent to admin requesting student access.')
        : errorMessage('An unexpected error has occurred.');
    },
  });

  const { loading, error, data } = useQuery(ASSESSMENT, {
    variables: {
      id: assessmentId,
      withLabs: true,
    },
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      setShowActiveLabBanner(!!data?.LabKeep_myLab?.instance);
    },
  });

  const splitStatus = useSplit('CONTENT_ACCESS');
  let hasPracticeExamAccess;

  if (splitStatus) {
    hasPracticeExamAccess = data?.Assessments_assessment?.contentAccess?.hasAccess;
  } else {
    const featureHasAccessLevel = makeFeatureHasAccessLevel(get(data, 'Packaging_userFeatureAccess.userFeatureAccess'));
    hasPracticeExamAccess = featureHasAccessLevel('practice-exams')('ENABLED');
  }

  useEffect(() => {
    const checkAndSetPluralsightUserStatus = async () => {
      const tokenData = await authService.getToken();
      const payload = jwtDecode(tokenData);
      setPluralsightRedirectUser(payload['https://ns.acloud.guru/pluralsightRedirectUser'] || false);
    };

    checkAndSetPluralsightUserStatus();
  }, [setPluralsightRedirectUser, pluralsightRedirectUser]);

  const { data: userIdentity } = useQuery(GET_USERID);
  const userId = get(userIdentity, 'userIdentity.id');
  const pluralsightId = get(userIdentity, 'userIdentity.pluralsightId');
  const originatesFrom = get(userIdentity, 'userIdentity.originatesFrom');

  useQuery(IS_USER_PS_B2B, {
    variables: {
      input: {
        pluralsightId,
      },
    },
    skip: !pluralsightId || originatesFrom !== 'PS',
    onCompleted: (data) => {
      const result = data?.OrganisationPlanUser_psUserPlanDetailsById?.planRoles[0]?.planId || false;
      setIsPluralsightOriginatingB2B(!!result);
    },
  });

  useEffect(() => {
    userId && identify(userId);
  }, [userId]);

  const { data: roles } = useQuery(GET_ROLES);
  const hasRestrictedFeatureAccess = get(roles, 'roles.organisationAdminOnly', false);
  const isOrganisationTeamCoordinatorOnly = get(roles, 'roles.teamCoordinatorOnly', false);

  const queryString = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
  const courseId = get(queryString, 'courseId');
  const history = useHistory();
  const [createAttempt, { loading: submitLoading }] = useMutation(CREATE_ATTEMPT, {
    variables: {
      input: {
        assessmentId,
        courseId,
        withLabs: true,
      },
    },
    update(cache, { data }) {
      cache.writeQuery({
        query: CURRENT_ATTEMPT.QUERY,
        variables: {
          input: {
            id: data.Assessments_createAttempt.id,
            assessmentId,
          },
          contentType: 'PRACTICE_EXAM',
        },
        data: {
          Assessments_v2_attempt: data.Assessments_createAttempt,
        },
      });
      history.push(`/${assessmentId}/exam/${data.Assessments_createAttempt.id}`);
    },
    onError() {
      showErrorMessage();
    },
  });

  if (data && get(data, 'Assessments_assessment.assessmentType') !== 'practiceExam') {
    return <NotFound />;
  }

  const examTitle = get(data, 'Assessments_assessment.title', '');
  const timeLimit = get(data, 'Assessments_assessment.timeLimit');
  const numberOfQuestions = get(data, 'Assessments_assessment.numberOfQuestions');
  const numberOfLabs = get(data, 'Assessments_assessment.numberOfLabs');
  const totalQuestionsCount = get(data, 'Assessments_assessment.totalQuestionsCount');
  const coverImageUrl = get(data, 'Assessments_assessment.coverImageUrl');
  const myLab = get(data, 'LabKeep_myLab.instance');
  const attemptHistory = get(data, 'Assessments_attemptHistory', []);
  const hasBeenAttempted = !!attemptHistory.length;

  const hasActiveLab = numberOfLabs > 0 && showActiveLabBanner && !loading;

  const showQuestionCountCallOut = numberOfQuestions * QUESTION_COUNT_MULTIPLIER < totalQuestionsCount;

  const handleButtonContent = (teamCoordinator, restrictedFeatureAccess) => {
    if (teamCoordinator) {
      return 'Request access';
    }
    if (restrictedFeatureAccess) {
      return 'Unlock student access to take this practice exam';
    }
    return 'Upgrade now to take this practice exam';
  };

  const handleUpgrade = () => {
    if (isOrganisationTeamCoordinatorOnly) {
      sendAccessRequestEmail();
      track('Student access - request access button - practice exam');
    }

    if (hasRestrictedFeatureAccess) {
      track('Student access - upgrade button - practice exam');
      return window.location.assign(`${ACG_BUSINESS_DOMAIN}/users`);
    }

    if (pluralsightRedirectUser) {
      return window.location.assign(`${PLURALSIGHT_LIBRARY_URL}`);
    }

    return window.location.assign(`${ACG_DOMAIN}/pricing`);
  };

  const renderActionButton = () => {
    if (loading) {
      return <ActionButton loading={loading}>Loading...</ActionButton>;
    }

    if (hasPracticeExamAccess && !hasRestrictedFeatureAccess) {
      return (
        <StyledStartExamButton
          data-testid="start-practice-exam-button"
          onClick={createAttempt}
          loading={submitLoading}
          disabled={error || hasActiveLab || (userBanned && numberOfLabs > 0)}
          hasBeenAttempted={hasBeenAttempted}
        />
      );
    }

    return isPluralsightOriginatingB2B ? (
      <ActionButton href={`${LEARN_URL}/upgrade-b2b?source=exam&id=${assessmentId}`} type="positive">
        Why is this locked?
      </ActionButton>
    ) : (
      <ActionButton type="positive" onClick={handleUpgrade} disabled={error} loading={sendAccessRequestLoading}>
        {handleButtonContent(isOrganisationTeamCoordinatorOnly, hasRestrictedFeatureAccess)}
      </ActionButton>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`Practice Exam – ${examTitle} – A Cloud Guru`}</title>
      </Helmet>
      {hasActiveLab && (
        <ActiveLabBanner
          onCancelClick={() => {
            track('Practice Exam Cancel Lab Clicked', {
              assessmentId,
              labId: myLab.uuid,
            });

            cancelLab({ variables: { labId: myLab.uuid } });
          }}
          loading={cancelLabLoading}
        />
      )}
      <Container data-testid="start-page-container">
        <SubContainer>
          <LogoHeader />
          {userBanned && <UserBanned />}
          <ExamCard>
            <Header
              loading={loading || error}
              timeLimit={timeLimit}
              numberOfQuestions={numberOfQuestions}
              numberOfLabs={numberOfLabs}
              coverImageUrl={coverImageUrl}
              title={examTitle}
            />
            <DividingLine></DividingLine>
            <CallOutContainer>
              {showQuestionCountCallOut && (
                <QuestionPoolCallOut
                  title="Question pool"
                  bodyText={
                    <span>
                      Each attempt pulls {numberOfQuestions} questions from a pool of{' '}
                      <strong>{totalQuestionsCount} unique questions</strong>.
                    </span>
                  }
                  icon={<DocumentIcon />}
                />
              )}
              <UnlimitedAttemptsCallOut
                title="Unlimited attempts"
                bodyText="Each attempt is randomly generated. Retake the exam to gain confidence."
                icon={<GraphIcon />}
              />
            </CallOutContainer>
            {renderActionButton()}
            {hasActiveLab && (
              <ActiveLabsMessage>You can't start a practice exam while you have a lab running.</ActiveLabsMessage>
            )}
            <Skeleton loading={loading || error} title={false}>
              <TextSummary numberOfLines="2">
                <Markdown
                  data-testid="start-page-exam-description"
                  text={get(data, 'Assessments_assessment.description')}
                />
              </TextSummary>
            </Skeleton>
            <AttemptHistory
              loading={loading || error}
              assessmentId={assessmentId}
              attempts={attemptHistory}
              hasPracticeExamAccess={hasPracticeExamAccess}
              activeLabInstanceUuid={myLab?.uuid}
            />
            <Domains
              loading={loading || error}
              questionPools={get(data, 'Assessments_assessment.questionPool', [])}
              labs={get(data, 'Assessments_assessment.labs', [])}
            />
          </ExamCard>
        </SubContainer>
      </Container>
    </>
  );
};

const Container = styled.main`
  background: ${colours.$lightGrey500};
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubContainer = styled.div`
  width: 758px;
  margin: 0 0 80px 0;
  padding: 0 24px 24px 24px;
`;

const ExamCard = styled(Card)`
  box-shadow: 0px 2px 14px rgba(173, 186, 208, 0.303431);
  border-radius: 5px;
`;

const DividingLine = styled.hr`
  height: 1px;
  border-top: ${colours.lightGrey500};
  margin: 16px 0 0 0;
`;

const actionButtonStyles = css`
  width: 100%;
  margin: 0 0 16px 0;
`;

const ActionButton = styled(Button)`
  ${actionButtonStyles}

  // Fighting antd styles here
  &&& {
    padding-top: 6px !important;
  }
`;

const StyledStartExamButton = styled(StartExamButton)`
  ${actionButtonStyles};
`;

const ActiveLabsMessage = styled.p`
  text-align: center;
  font-size: 14px;
  margin: -12px 0 16px;
`;

const CallOutContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionPoolCallOut = styled(CallOut)`
  flex: 1 1 0;
  margin-right: 10px;
`;

const UnlimitedAttemptsCallOut = styled(CallOut)`
  flex: 1 1 0;
`;

export { Start, ASSESSMENT, GET_ROLES, GET_USERID };
