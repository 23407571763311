import React from 'react';
import styled from 'styled-components';
import { colours, Progress } from '@a-cloud-guru/rainbow-ui';

const determineDonutColour = (score) => (score > 74 ? colours.green700 : colours.red700);
const DomainScoreDonut = ({ domainScore, index }) => {
  return (
    <Donut
      type="circle"
      width={78}
      percent={domainScore}
      strokeColor={determineDonutColour(domainScore)}
      trailColor={colours.lightGrey500}
      strokeLinecap="square"
      strokeWidth={8}
      status="normal"
      data-testid={`domain-score-donut-${index}`}
    />
  );
};

const Donut = styled(Progress)`
  width: 78px;
  height: 78px;
  margin: 0px 16px 0px -61px;

  .ant-progress-inner {
    background-color: ${colours.lightGrey300};
    box-shadow: 2px 2px 9px 3px rgba(173, 186, 208, 0.5);
  }

  .ant-progress-text {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    color: ${(props) => props.strokeColor};
  }
`;

export { DomainScoreDonut, determineDonutColour };
