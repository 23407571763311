import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { SubmitProvider } from '../../contexts/submit/SubmitContext';
import { Lab } from './Lab';

const LabContainer = () => {
  const { attemptId, assessmentId } = useParams();
  const history = useHistory();

  return (
    <SubmitProvider attemptId={attemptId} assessmentId={assessmentId} history={history}>
      <Lab />
    </SubmitProvider>
  );
};

export { LabContainer };
