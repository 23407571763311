import React from 'react';
import { colours } from '@a-cloud-guru/rainbow-ui';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '../images/arrow.svg';
import { StyledLink } from './StyledLink';

const ContinueLink = ({ url, ...props }) => (
  <StyledLink href={url} color={colours.blue700} hoverColor={colours.blue500} {...props}>
    Continue Exam <ArrowIcon />
  </StyledLink>
);

const ArrowIcon = styled(Arrow)`
  margin-left: 4px;
`;

export { ContinueLink };
