import React from 'react';
import styled from 'styled-components';
import { Button } from '@a-cloud-guru/rainbow-ui';
import { ReactComponent as FlagIcon } from './images/flag-icon.svg';

const FlagButton = ({ flagged, toggleQuestionFlag }) => {
  const message = flagged ? 'Unflag for Review' : 'Flag for Review';

  return (
    <IconButton onClick={toggleQuestionFlag} data-testid="exam-page-flag-question">
      <ButtonIcon>
        <FlagIcon />
      </ButtonIcon>
      {message}
    </IconButton>
  );
};

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
`;

const ButtonIcon = styled.span`
  width: 16px;
  height: 18px;
  margin-right: 6px;
`;

export { FlagButton };
