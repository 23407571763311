import { gql } from '@apollo/client';

const SUBMIT_LAB_MUTATION = gql`
  mutation Assessments_submitLab(
    $input: Assessments_SubmitLabInput!
    $contentType: Assessments_AccessPermissions_ContentType!
  ) {
    Assessments_v2_submitLab(input: $input, contentType: $contentType) {
      __typename
      ... on Assessments_Attempt {
        id
      }
      ... on Assessments_AttemptAlreadyGraded {
        message
      }
      ... on Assessments_NoActiveLabInstance {
        message
      }
      ... on Assessments_SubmitLabError {
        message
      }
    }
  }
`;

export { SUBMIT_LAB_MUTATION };
