import React, { createContext, useState } from 'react';

const SelectedAnswersContext = createContext({});

const SelectedAnswersProvider = ({ children }) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  return (
    <SelectedAnswersContext.Provider
      value={{
        selectedAnswers,
        setSelectedAnswers,
      }}
    >
      {children}
    </SelectedAnswersContext.Provider>
  );
};

export { SelectedAnswersContext, SelectedAnswersProvider };
