import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import { colours, Button } from '@a-cloud-guru/rainbow-ui';
import { UPDATE_QUESTION_INDEX } from './../../lib/sharedGraphql';
import { ReactComponent as ArrowRight } from './images/arrow-right.svg';
import { ReactComponent as ArrowLeft } from './images/arrow-left.svg';
import { errorMessage } from '../../components/Message/Message';

const PrevNextNavigation = ({ attempt, assessmentId }) => {
  const showErrorMessage = (message) =>
    errorMessage(message || 'Oops, something went wrong! Please try again.', 'exam-page');

  const [updateIndex] = useMutation(UPDATE_QUESTION_INDEX.MUTATION, {
    onError() {
      showErrorMessage();
    },
    onCompleted(data) {
      if (!get(data, 'Assessments_v2_updateQuestionIndex')) {
        showErrorMessage(
          'You do not have permission to perform this action. Check your subscription and try again later.'
        );
        return;
      }

      const message = get(data, 'Assessments_v2_updateQuestionIndex.message');
      if (message) {
        showErrorMessage('This exam has been completed');
      }
    },
  });

  const selectQuestion = (questionNumber) => {
    updateIndex(UPDATE_QUESTION_INDEX.options({ questionNumber, attempt, assessmentId }));
  };

  const { currentQuestionIndex, numberOfQuestions } = attempt;

  const backClick = () => {
    if (currentQuestionIndex === 0) {
      selectQuestion(numberOfQuestions - 1);
    } else {
      selectQuestion(currentQuestionIndex - 1);
    }
  };

  const nextClick = () => {
    if (currentQuestionIndex === numberOfQuestions - 1) {
      selectQuestion(0);
    } else {
      selectQuestion(currentQuestionIndex + 1);
    }
  };

  return (
    <PrevNextContainer>
      <StyledButton type="link" onClick={backClick} data-testid="exam-page-previous-question-button">
        <ArrowLeft />
        &nbsp;Back
      </StyledButton>
      <StyledButton type="link" onClick={nextClick} data-testid="exam-page-next-question-button">
        Next&nbsp;
        <ArrowRight />
      </StyledButton>
    </PrevNextContainer>
  );
};

const PrevNextContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  color: ${colours.blue700} !important;
  margin-top: 22px;
  height: fit-content !important;

  &.ant-btn-link:hover {
    color: ${colours.blue700} !important;
    border-color: ${colours.blue700} !important;
  }
`;

export { PrevNextNavigation };
