import React from 'react';
import { useMutation } from '@apollo/client';
import { SUBMIT_EXAM } from '../../lib/sharedGraphql';

const SubmitContext = React.createContext();

const SubmitProvider = ({ children, attemptId, assessmentId, history }) => {
  const [submitExam, { loading, called }] = useMutation(
    SUBMIT_EXAM.MUTATION,
    SUBMIT_EXAM.options({
      attemptId,
      assessmentId,
      history,
    })
  );

  return <SubmitContext.Provider value={{ submitExam, loading, called }}>{children}</SubmitContext.Provider>;
};

export { SubmitProvider, SubmitContext };
