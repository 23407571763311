import React from 'react';
import { Card as RainbowCard } from '@a-cloud-guru/rainbow-ui';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import { SectionHeading } from '../SectionHeading';

const Card = ({ title, children, loading, ...rest }) => {
  return (
    <DropShadowCard {...rest}>
      <Skeleton title={true} loading={loading} paragraph={false}>
        {title ? <StyledHeading>{title}</StyledHeading> : ''}
      </Skeleton>
      {children}
    </DropShadowCard>
  );
};

const StyledHeading = styled(SectionHeading)`
  margin-bottom: 12px;

  .ant-card-head {
    border-bottom: 0;
  }
`;

const DropShadowCard = styled(RainbowCard)`
  box-shadow: 0px 2px 14px rgba(173, 186, 208, 0.303431);
  border-radius: 5px;
  margin-bottom: 16px;
`;

export { Card };
