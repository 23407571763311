import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';

const StyledLink = styled.a`
  font-weight: 600;
  color: ${(props) => props.color};
  padding: 0 10px;
  position: relative;
  right: -10px;

  svg {
    path {
      fill: ${(props) => props.color};
    }
  }

  &:hover {
    color: ${(props) => props.hoverColor};
    svg {
      path {
        fill: ${(props) => props.hoverColor};
      }
    }
  }

  &:focus {
    border-radius: 4px;
    box-shadow: ${colours.blue500} 0px 0px 4px 2px;
  }
`;

export { StyledLink };
