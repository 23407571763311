import React from 'react';
import { Rating } from '../../components/Rating/Rating';

const RateQuestion = ({ questionId, assessmentId }) => {
  return (
    <Rating
      type="compact"
      category="exam"
      entityId={`exam::${assessmentId}::question::${questionId}`}
      defaultText="Rate this question"
      upvotedText="You liked this question"
      downvotedText="You disliked this question"
    />
  );
};

export { RateQuestion };
