import { makeAuthService } from '@a-cloud-guru/auth0-wrapper';
import { getConfig } from '../config/get-config';
import urlJoin from 'url-join';

const { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_CUSTOM_DOMAIN, AUTH0_API_AUDIENCE, ACG_PRACTICE_EXAM_DOMAIN } =
  getConfig();

const authService = makeAuthService({
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE,
  DEFAULT_REDIRECT_URI: urlJoin(ACG_PRACTICE_EXAM_DOMAIN, 'login', 'callback'),
});

export { authService };
