import React from 'react';
import styled from 'styled-components';
import { colours, Card, Box, Progress, Skeleton } from '@a-cloud-guru/rainbow-ui';
import { SectionHeading } from '../../components/SectionHeading';

const DomainBreakdown = ({ domains, loading }) => {
  const domainList = domains.map((domain, index) => {
    return (
      <DomainRow key={index} data-testid={`domain-breakdown-${index}`}>
        <DomainName data-testid={`domain-name-${index}`}>{domain.domainName}</DomainName>
        <ScoreContainer>
          <Box width={124}>
            <ScorePercentage percent={domain.score} showInfo={false} data-testid={`domain-percentage-${index}`} />
          </Box>
          <DomainScore data-testid={`domain-score-${index}`}>{domain.score}%</DomainScore>
        </ScoreContainer>
      </DomainRow>
    );
  });

  return (
    <DomainBreakdownCard>
      <SectionHeading>Domain Breakdown</SectionHeading>
      <Skeleton loading={loading} title={false}>
        <Box mt={8}>{domainList}</Box>
      </Skeleton>
    </DomainBreakdownCard>
  );
};

const DomainBreakdownCard = styled(Card)`
  margin: 40px 0px;
  box-shadow: 0px 2px 14px rgba(173, 186, 208, 0.303431);
  border-radius: 5px;

  .ant-card-body {
    padding: 24px;
  }
`;

const DomainRow = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  box-shadow: inset 0px -1px 0px #d7e0f1;
  padding-bottom: 8px;
  margin: 8px 0;
  align-items: center;
`;

const DomainName = styled.span`
  max-width: 70%;
  font-weight: 600;
  color: ${colours.darkGrey900};
`;

const ScoreContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ScorePercentage = styled(Progress)`
  .ant-progress-inner {
    height: 21px;
    border-radius: 3px;
    background-color: ${colours.lightGrey300};
  }
  .ant-progress-bg {
    height: 21px !important;
    border-radius: 0px;
    background-color: ${colours.orange700};
  }
`;

const DomainScore = styled.span`
  width: 45px;
  margin-left: 24px;
  text-align: right;
`;

export { DomainBreakdown };
