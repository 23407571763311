import React from 'react';
import { colours, Button, Form, Input } from '@a-cloud-guru/rainbow-ui';
import { Skeleton } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { Card } from '../../components/Card/Card';
import { Spinner } from '../../components/Spinner';
import { CopyButton } from './CopyButton';
import { ReactComponent as QuestionMarkIcon } from './question-mark-icon.svg';
import { copyText } from '../../lib/copyText';
import { successMessage } from '../../components/Message/Message';

const LoadingCredentialsFields = () => {
  return (
    <>
      <div>
        <SkeletonLabel active title={false} paragraph={{ rows: 1, width: 65 }} />
        <SkeletonInput active loading={true} style={{ width: 295 }} />
      </div>
      <div>
        <SkeletonLabel active title={false} paragraph={{ rows: 1, width: 65 }} />
        <SkeletonInput active loading={true} style={{ width: 295 }} />
      </div>
    </>
  );
};

const CredentialsFields = ({ username, password, onCopyButtonClick }) => {
  return (
    <StyledForm colon={false} layout="vertical">
      <StyledFormItem label="Username" name="credentials_username" initialValue={username}>
        <StyledInput
          data-testid="credentials_username"
          placeholder="username"
          bordered={false}
          readOnly
          suffix={<CopyButton value={username} onClick={onCopyButtonClick} />}
        />
      </StyledFormItem>
      <StyledFormItem label="Password" name="credentials_password" initialValue={password}>
        <StyledInput
          data-testid="credentials_password"
          placeholder="password"
          bordered={false}
          readOnly
          suffix={<CopyButton value={password} onClick={onCopyButtonClick} />}
        />
      </StyledFormItem>
    </StyledForm>
  );
};

const Credentials = ({
  username = '',
  password = '',
  labUrl = '',
  isLoading = false,
  onCopyCredentials = () => {},
  onHelpLinkClicked = () => {},
}) => {
  return (
    <Card title="Credentials">
      <GlobalStyle />
      {isLoading ? (
        <LoadingCredentialsFields />
      ) : (
        <CredentialsFields username={username} password={password} onCopyButtonClick={onCopyCredentials} />
      )}
      <StyledButton
        data-testid="credentials_labUrl"
        onClick={() => {
          copyText(labUrl);
          successMessage('Copied to clipboard, please open link in Incognito window');
        }}
        type="primary"
        target="_blank"
        block={true}
        disabled={isLoading}
      >
        Open Link in Incognito Window
      </StyledButton>
      <CredentialsFooter>
        {isLoading ? (
          <>
            <Spinner size="16px" colour={colours.blue} /> Loading Lab
          </>
        ) : (
          <StyledLink
            href="https://help.pluralsight.com/help/start-labs"
            target="_blank"
            rel="noreferrer"
            onClick={onHelpLinkClicked}
          >
            <QuestionMarkIcon />
            How do I connect?
          </StyledLink>
        )}
      </CredentialsFooter>
    </Card>
  );
};

const StyledForm = styled(Form)`
  margin-bottom: 24px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;

  .ant-form-item-label > label {
    color: ${colours.darkGrey900};
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
  }
`;

const StyledButton = styled(Button)`
  :focus {
    border-radius: 4px;
    box-shadow: ${colours.blue500} 0px 0px 4px 2px;
  }
`;

const StyledInput = styled(Input)`
  background: ${colours.lightGrey300};
  font-size: 14px;
  color: ${colours.darkGrey700};
  border-radius: 3px;
  line-height: 36px;
  padding-right: 0;

  &:hover,
  &:focus {
    background-color: ${colours.lightGrey300};
    line-height: 36px;
  }
`;

const CredentialsFooter = styled.div`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  color: ${colours.blue};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Spinner} {
    margin-right: 12px;
  }
`;

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 9px;
  }
  :focus {
    border-radius: 4px;
    box-shadow: ${colours.blue500} 0px 0px 4px 2px;
  }
`;

const SkeletonLabel = styled(Skeleton)`
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    border-radius: 24px;
  }
`;

const SkeletonInput = styled(Skeleton.Input)`
  border-radius: 24px;
  margin-bottom: 12px;

  &.ant-skeleton-active .ant-skeleton-input {
    height: 44px;
  }
`;

const GlobalStyle = createGlobalStyle`
  a.ant-btn${StyledButton} {
    padding-top: 6px !important;
  }
`;

export { Credentials };
