import React from 'react';
import styled from 'styled-components';
import { colours } from '@a-cloud-guru/rainbow-ui';
import { Badge } from 'antd';

const NavigationItem = ({ isFlagged, isSubmitted, isActive, isCorrect, handleClick, children }) => {
  return (
    <FlagBadge dot={isFlagged} offset={[-8, 0]}>
      <StyledButton isActive={isActive} isCorrect={isCorrect} isSubmitted={isSubmitted} onClick={handleClick}>
        {children}
      </StyledButton>
    </FlagBadge>
  );
};

const StyledButton = styled.button`
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
  display: inline-block;
  width: 43px;
  height: 33px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px;
  margin: 0px 8px 8px 0px;
  border: none;
  letter-spacing: -1px;
  transition: 200ms all ease-in-out;

  background-color: ${(props) => {
    if (props.isActive) {
      if (props.isCorrect) {
        return colours.green700;
      }

      if (props.isCorrect === false) {
        return colours.red700;
      }

      if (props.isSubmitted === false) {
        return colours.darkGrey100;
      }

      return colours.blue;
    }

    if (props.isCorrect) {
      return colours.green200;
    }

    if (props.isCorrect === false) {
      return colours.red200;
    }

    if (props.isSubmitted) {
      return colours.blue200;
    }

    return colours.lightGrey300;
  }};

  color: ${(props) => {
    if (props.isActive) {
      return colours.white;
    }

    return colours.darkGrey800;
  }};

  &:focus {
    outline-color: ${(props) => {
      if (props.isCorrect) {
        return colours.green900;
      }

      if (props.isCorrect === false) {
        return colours.red900;
      }

      if (props.isSubmitted === false) {
        return colours.darkGrey600;
      }

      return colours.blue900;
    }};
  }

  &:hover {
    background-color: ${(props) => {
      if (props.isCorrect) {
        return colours.green700;
      }

      if (props.isCorrect === false) {
        return colours.red700;
      }

      if (props.isSubmitted === false) {
        return colours.darkGrey100;
      }
    }};

    color: ${(props) => {
      if (props.isSubmitted === false || props.isCorrect || props.isCorrect === false) {
        return colours.white;
      }
    }};
  }
`;

const FlagBadge = styled(Badge)`
  sup {
    width: 8px;
    height: 8px;
  }
`;

export { NavigationItem };
