const copyText = (value) => {
  const fakeElement = document.createElement('input');
  fakeElement.value = value;
  document.body.appendChild(fakeElement);
  fakeElement.select();
  document.execCommand('copy');
  fakeElement.remove();
};

export { copyText };
